.users-control {
  display: flex;
  width: 100%;
  flex: 1;
}

.user-control-page-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px 140px;
  box-sizing: border-box;
}

.users-control-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #19303E
}

.table-controls {
  display: flex;
}

.table-col-role {
  width: 180px;
  height: 32px;
  margin-right: auto;
  padding-left: 29px !important;
}

.table-col-properties {
  width: 110px;
  height: 14px;
}

.table-col-two-fa {
  width: 63px;
  height: 18px;
}

.table-col-active {
  width: 85px;
  height: 20px;
}

.table-last-col {
  visibility: visible !important;
  width: 65px;

}

.users-sort-arrow {
  cursor: pointer;
  border-radius: 1px;
  padding: 0 !important;
}

.users-table-row {
  position: relative;
  height: 64px;
  background: #FFFFFF;
}

.users-table-row-inactive {
  position: relative;
  height: 64px;
  background: rgba(25, 48, 62, 0.04);
}

.users-table-avatar {
  background: #A3B8C6;
  min-width: 32px;
  height: 32px;
  border-radius: 50px/50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.users-table-avatar-label {
  margin-bottom: 0 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  color: #FFFFFF;
  padding: 0 !important;
}

.users-table-invitees-avatar {
  background: #9AC4EC;
  min-width: 32px;
  height: 32px;
  border-radius: 50px/50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.users-table-email {
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #19303E;
}

.users-table-email label {
  cursor: pointer;

}

.users-table-empty-checkbox {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-image: url("/src/assets/icons/empty-checkbox.svg");
}

.users-table-checked-checkbox {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-image: url("/src/assets/icons/checked-checkbox.svg");
}

.users-table-permissions {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: #19303E;
  width: 75px;
  height: 14px;
  padding: 0 !important;
}

.users-table-permissions svg {
  margin-left: 4px !important;
  position: relative;
  top: -1px;
}

.users-table-two-fa {
  width: 18px;
  height: 18px;
  padding: 0 !important;
}

.users-table-active-switch {
  width: 42px;
  height: 20px;
  padding: 0 !important;
}

.edit-action-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 2px;
  padding: 0 !important;
}

.edit-action-button img {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.users-cols-sort-icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.invite-users-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 11px 16px;
  width: 139px;
  height: 40px;
  background: #009933;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.invite-users-btn svg {
  flex: none;
  flex-grow: 0;
  margin: 0px 5px;
}

.invite-users-btn label {
  cursor: pointer;
  width: 81px;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.invite-users-btn:hover {
  background: #00C141;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}

.users-table-filter {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 114px;
  height: 40px;
  background: #FFFFFF;
  padding: 0;
}

.users-table-search-bar {
  width: 260px;
  height: 40px;
}

.search-box {
  border: 1px solid rgba(25, 48, 62, 0.24);

}

.search-box img {
  width: 20px;
  margin: 0 5px;
}

.search-bar-input-field {
  width: 222px;
  height: 38px;
  border: none;
  /*background: url(/img/search.svg) #FFF center left no-repeat;*/
}

.search-bar-input-field:focus-visible {
  outline: none !important;
}

.main-checkbox {
  cursor: pointer;
  width: 18px;
  height: 18px;
  visibility: visible !important;
}

.users-table {
  width: 100%;
  table-layout: fixed;
}

.hidden-table-cols {
  visibility: hidden;
}

.users-table th {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #19303E;
}

.users-table th,
.users-table td {
  padding: 0 10px;
}

.users-table .col-w-30 {
  width: 30px;
}

.users-table .col-w-50 {
  width: 90px;
}

.users-table .col-w-100 {
  width: 100px;
}

.table-col-labels {
  text-transform: uppercase;
}

.table-col-buttons-group {
  padding: 0px 0px 0px 410px !important;
  width: 335px;
}

.user-email-name-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.users-table-names {
  height: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #19303E;
  margin-bottom: 0 !important;
}

.users-table-names label {
  cursor: pointer;
}

.users-table-email {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.8);
}

.table-col-users {
  width: 300px;
  height: 32px;
}

.table-col-users label {
  margin-bottom: 0 !important;
}

.table-row-buttons-group {
  padding: 0px 0px 0px 410px !important;
  width: 335px;
}

.users-table-filter-dropdown {
  position: absolute;
  z-index: 3;
  background-color: #FFFFFF;
  display: flex;
  width: 380px;
  height: 250px;
  justify-content: space-around;
}

.filter-dropdown-button {
  cursor: pointer;
  width: 114px;
  height: 40px;
  align-items: center;
  justify-content: space-evenly;
  background: #FFFFFF;
  border: 1px solid rgba(25, 48, 62, 0.24);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
  border-radius: 2px;
}

.filter-dropdown-button-open {
  cursor: pointer;
  background: #19303E;
  width: 114px;
  height: 40px;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(25, 48, 62, 0.24);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
  border-radius: 2px;
}

.filter-dropdown-button label {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #19303E;
}

.filter-dropdown-button-open label {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
}

.filter-dropdown-content {
  padding: 32px 0 0 0;
}

.two-fa-and-status-tab {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 40px;
}

.filter-dropdown-labels {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: rgba(25, 48, 62, 0.32);
}

.filter-dropdown-option {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.8);
  margin-bottom: 0;
}

.users-table-delete-btn {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: #19303E;
}

.users-table-delete-btn label {
  cursor: pointer;
}

.checked-users-label {
  width: 115px;
  margin: 2px 0px 0px 25px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #009933;
}

.col-with-padding {
  padding-left: 60px;
}

.users-table-properties-container {
  z-index: 1;
  position: absolute;
  background-color: white;
  visibility: hidden;
  margin-left: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(25, 48, 62, 0.08);
  border-radius: 2px;
  width: 170px;
}

.users-table-properties-container label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: rgba(25, 48, 62, 0.48);
}

.users-table-properties-container p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: #19303E;
}

.properties-icon {
  position: relative;
}

.properties-icon-disabled {
  position: relative;
  opacity: 0.2;
}

.properties-icon:hover~.users-table-properties-container {
  visibility: visible;
}

.users-table-actions {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(25, 48, 62, 0.08);
  border-radius: 2px;
  width: 90px;
  height: 90px;
  position: absolute;
  z-index: 1;
  right: 55px;
}

.users-table-actions-label {
  text-transform: uppercase;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: rgba(25, 48, 62, 0.32);
}

.users-table-actions-options {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.8);

}

.users-table-actions-options label {
  cursor: pointer;
  margin: 2px 0px 0px 20px;
}

.users-table-actions-edit-option {
  padding-bottom: 8px;
  background-image: url('/src/assets/icons/pen.svg');
  background-repeat: no-repeat;
  height: 26px;
}

.users-table-actions-edit-option:hover {
  background: rgba(25, 48, 62, 0.04);
  border-radius: 2px;
  color: #00C141;
  background-image: url('/src/assets/icons/green-pen.svg');
  background-repeat: no-repeat;
}

.users-table-actions-delete-option {
  background-image: url('/src/assets/icons/bin.svg');
  background-repeat: no-repeat;
  height: 26px;
}

.users-table-actions-delete-option:hover {
  background: rgba(25, 48, 62, 0.04);
  border-radius: 2px;
  color: #00C141;
  background-image: url('/src/assets/icons/green-bin.svg');
  background-repeat: no-repeat;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.edit-action-close-button {
  background: rgba(25, 48, 62, 0.08);
  border-radius: 2px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.edit-action-close-button img {
  width: 12px;
  height: 12px;
}

.users-table-roles {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #19303E;
  padding-left: 20px;
}

.users-table-send-invitation-email {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid rgba(25, 48, 62, 0.24);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
  border-radius: 2px;
  width: 32px;
  height: 32px;
}

.invitation-email-tooltip {
  visibility: hidden;
  width: 120px;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: 35px;
  top: -7px;
  height: 45px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.users-table-send-invitation-email:hover .invitation-email-tooltip {
  visibility: visible;
}

.learn-more-sign {
  height: 24px;
}

.learn-more-label {
  text-decoration-line: underline;
  color: #009933;
  height: 24px;
}

.delete-modal-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: #009933;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.delete-modal-submit-btn-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: rgba(25, 48, 62, 0.08);
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
  pointer-events: none;
}

.delete-modal-submit-btn label {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.delete-modal-submit-btn-disabled label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.32);
  margin-bottom: 0 !important;
  pointer-events: none;
}

.delete-modal-submit-btn:hover {
  cursor: pointer;
  background: #00C141;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}

.delete-modal-submitting-btn {
  background: #00C141;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 42.5px;
  width: 169px;
  height: 40px;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}


.delete-modal-submitting-btn label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;

}

.delete-modal-submitting-btn i {
  margin: 1px 0px 0px 15px;
  font-size: 15px;
  color: #FFFFFF;
}

.delete-modal-submit-btn-failed-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  min-height: 40px;
  background: #00C141;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.delete-modal-submit-btn-failed-submit label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.delete-modal-cancel-btn {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-left: 35px;
}

.avatar-name-mail-container {
  width: fit-content;
}