.list-header .choose-view-container .list-current-view {
    line-height: 20px;
    padding: 5px 20px 5px 10px;
    font-size: 15px;
    background-color: transparent;
    margin: 0 10px 0 0;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    border: none;
}
.list-header .choose-view-container .list-current-view i {
    position: absolute;
    right: 5px;
    top: 6px;
}
.list-header .choose-view-container .list-view-selector {
    margin: 0 10px 0 0;
    background-color: #fff;
    border: 1px solid #444;
    border-top: none;
    padding: 5px 0;
    z-index: 1000;
    line-height: 20px;
    position: absolute;
    overflow-y: scroll;
    max-height: 500px;
}
.list-header .choose-view-container .list-view-selector .list-view-option {
    display: grid;
    grid-template-columns: 20px auto;
    padding: 0 10px;
}
.list-header .choose-view-container .list-view-selector a {
    color: #444;
    text-decoration: none;
    display: inline-flex;
    padding: 5px 0;
}
.list-header .choose-view-container .list-view-selector a i {
    line-height: 20px;
}
.list-header .choose-view-container .list-view-selector a {
    color: #444;
    text-decoration: none;
    display: inline-flex;
    padding: 5px 0;
}
.list-header .choose-view-container .list-view-selector a.create-new-view {
    display: flex;
    border-top: 1px solid #f5f4f5;
    justify-content: center;
}
.list-header .choose-view-container .list-current-view.menu-active, #listPage .list-header .choose-view-container .list-current-view:hover {
    border: 1px solid #444;
}