body {
    background-color:#FBFBFB;
}

.name-your-workspace-header-text {
    width: 363px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #19303E;
    margin-right: 140px;
}

.name-your-workspace-reminder {
    width: 501px;
    height: 20px;
    margin-top: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(25, 48, 62, 0.64);
}

.workspace-url-header-text {
    width: 120px;
    height: 14px;
    margin-top: 62px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #19303E;
    margin-left: -170px;
}

.workspace-url-additional-info {
    margin-top: 60px;
    margin-left: 10px;
    cursor: pointer;
}

.workspace-url-plus-info {
    width: 160px;
}

.workspace-input-holder {
    width: 419px;
    height: 56px;
    margin-top: 18px;
    margin-right: 85px;
    background: rgba(25, 48, 62, 0.04);
    border-radius: 2px;
}

.workspace-actual-input {
    width: 403px;
    height: 40px;
    margin-left: 8px;
    margin-top: 8px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
}

.fsapp-io-holder {
    width: 94px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 0px 2px 2px 0px;
    margin-top: 8px;
    margin-right: 8px;
}

.fsapp-io-holder-text {
    width: 62px;
    height: 20px;
    margin-top: 9px;
    margin-left: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #19303E;
}

.workspace-url-additional-details {
    width: 470px;
    height: 32px;
    margin-right: 31px;
    margin-top: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(25, 48, 62, 0.64);
}

.invalid-registration-workspace-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #E00909;
    margin-top: 3px;
    margin-right: 372px;
    margin-bottom: -13px;
}
