#workspace-settings {
  display: flex;
  width: 100%;
  flex: 1;
}

.workspace-settings-page-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px 140px;
  box-sizing: border-box;
}

.workspace-settings-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #19303E
}

.workspace-name-field {
  width: 403px;
  height: 82px;
}

.workspace-settings-label {
  width: 128px;
  height: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: rgba(25, 48, 62, 0.48);
}

.workspace-name-field input {
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
}

.workspace-name-field span {
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.currency-timezone-container {
  flex-direction: row;
  width: 403px;
  height: 82px;
  justify-content: space-between;
  margin-top: 15px;
}

.workspace-currency {
  width: 170px;
}

#currency-field {
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16) !important;
  border-radius: 2px !important;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
  appearance: 1 !important;
  -webkit-appearance: auto !important;
}

#currency-field:focus {
  border-color: #42ff81 !important;
}

.workspace-timezone {
  width: 170px;
}

#timezone-field {
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16) !important;
  border-radius: 2px !important;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
  appearance: 1 !important;
  -webkit-appearance: auto !important;
}

#timezone-field:focus {
  border-color: #42ff81 !important;
}

.workspace-date-format {
  width: 403px;
  height: 66px;
}

#date-format-field {
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16) !important;
  border-radius: 2px !important;
  box-sizing: border-box;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
  appearance: 1 !important;
  -webkit-appearance: auto !important;
}

#date-format-field:focus {
  border-color: #42ff81 !important;
}

.workspace-save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: #009933;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.workspace-save-btn-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: rgba(25, 48, 62, 0.08);
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
  pointer-events: none;
}

.workspace-save-btn label {
  cursor: pointer;
  width: 35px;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.workspace-save-btn-disabled label {
  width: 35px;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.32);
  margin-bottom: 0 !important;
  pointer-events: none;
}

.workspace-save-btn:hover {
  cursor: pointer;
  background: #00C141;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}

.workspace-saving-btn {
  background: #00C141;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 42.5px;
  width: 142px;
  height: 40px;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}

.workspace-saving-btn label {
  width: 49px;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.workspace-saving-btn i {
  margin: 1px 0px 0px 15px;
  font-size: 15px;
  color: #FFFFFF;
}

.workspace-save-btn-failed-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  min-height: 40px;
  background: #00C141;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.workspace-save-btn-failed-submit label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.workspace-api-key {
  width: 403px;
  flex-direction: column;
}

.workspace-api-key-field {
  cursor: text;
  width: 260px;
  height: 40px;
  resize: none;
  border: 1px solid rgba(25, 48, 62, 0.16);
  outline: none;
  padding: 0px 0px 0px 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.api-key-textarea-button-container {
  justify-content: space-between;
}

.refresh-api-key-btn {
  cursor: pointer;
  border-radius: 2px !important;
  border: none;
  width: 125px;
  line-height: 38px;
  padding: 0 16px;
  text-align: center;
  background: #4F81EE;
  color: white;
  display: block;
}

.refresh-api-key-btn label {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.refresh-api-key-btn:hover {
  /* transition: all 0.5s ease; */
  background: #245A99;
  text-decoration: none;
}

.refresh-api-key-btn-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: rgba(25, 48, 62, 0.08);
  border-radius: 2px;
  pointer-events: none;
}

.refresh-api-key-btn-disabled label {
  width: 35px;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.32);
  margin-bottom: 0 !important;
  pointer-events: none;
}

.refreshing-api-key-btn {
  background: #245A99;
  border-radius: 2px !important;
  border: none;
  min-width: 125px;
  line-height: 38px;
  padding: 0 16px;
  text-align: center;
  color: white;
  display: block;
}

.refreshing-api-key-btn label {
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.refreshing-api-key-btn i {
  margin: 1px 0px 0px 5px;
  font-size: 15px;
  color: #FFFFFF;
}

.refreshing-api-key-btn img {
  margin: 1px 0px 0px 5px;
  font-size: 15px;
  color: #FFFFFF;
}

.refresh-api-key-btn-failed-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  min-height: 40px;
  background: #245A99;
  border-radius: 2px;
}

.refresh-api-key-btn-failed-submit label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.api-key-modal-styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  width: 512px;
  height: 150px;
  z-index: 1000;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #19303E;
}

.api-key-modal-overlay-styles {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.close-api-key-container {
  margin: 15px 0 0 auto;
  width: 40px;
}

.close-api-key-modal-button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  color: #19303E;
  font-size: 1.8rem;
  margin-top: 0;
  font-weight: normal;
  margin-right: 5px;
}

.api-key-modal-middle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}