#user-security-page {
  display: flex;
  width: 100%;
  height: 100vh;
  flex: 1;
}

.security-tab-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 56px 146px;
  box-sizing: border-box;
}

.user-security-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #19303E
}

.change-pass-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: rgba(25, 48, 62, 0.48);
  text-transform: uppercase;
}

.new-password-interface {
  margin-top: 15px;
  height: 92px;
}

.new-password-interface input {
  width: 403px;
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
}

.new-password-interface label {
  width: 128px;
  height: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #19303E;
  margin-bottom: 0 !important;
}

.confirm-password-interface {
  margin-top: 17px;
  height: 86px;
}

.confirm-password-interface label {
  width: 155px;
  height: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #19303E;
  margin-bottom: 0 !important;
}

.confirm-password-interface input {
  width: 403px;
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
}

.security-password-details-text {
  width: 370 px;
  height: 115 px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(25, 48, 62, 0.64);
}