.body-wrapper {
  background-color: #dbdbdb;
  .sites-grid-container {
    display: flex;
    flex-direction: column;
    .sites-breadcrumbs {
      .breadcrumb {
        background-color: inherit;
        .breadcrumb-item {
          display: flex;
          .breadcrumb-link {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #8c979f;
          }
          .breadcrumb-link-active {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #19303e;
          }
        }
      }
    }
    .sites-header {
      margin-top: 50px;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
      .search-field {
        width: 100%;
        height: 48px;
        margin-right: 20px;
        display: flex;
        background-color: #ffffff;
        border-radius: 4px;
        margin-bottom: 20px;
        .search-field-button {
          border: none;
          background: transparent;
          border-radius: 4px;
        }
        .search-field-input {
          height: 100%;
          width: 100%;
          border: none;
          border-radius: 4px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #8c979f;
        }
        .search-field-input:focus,
        :focus-visible {
          outline-color: #00c141;
        }
      }
      .sites-header-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 110%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #19303e;
        margin-bottom: 45px;
      }
      .sites-createsite-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 17px 16px;
        border: none;
        width: 193px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        font-feature-settings: 'liga' off;
        background: #00c141;
        border-radius: 5px;
      }
      .fs-button:hover {
        background-color: green;
        transition: all .4s ease-in-out;
      }
    }
    .sites-items-container {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 80px;
      .sites-item {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        margin-bottom: 15px;
        height: 120px;
        background: #f2f2f2;
        border-radius: 4px;
        padding-right: 0px;
        padding-left: 0px;
        .sites-item-img {
          width: 100%;
          .sites-img {
            width: 100%;
            height: 300px;
            border-radius: 4px;
            object-fit: cover;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.8%, rgba(0, 0, 0, 0.2) 100%);
            border-radius: 4px;
          }
          .sites-item-not-published-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px;
            width: 93px;
            height: 24px;
            margin-left: 15px;
            background: #e31e18;
            position: absolute;
            top: 10px;
            right: 10px;
            top: 3;
            border-radius: 4px;
            .sites-item-not-published-text {
              position: static;
              width: 81px;
              height: 12px;
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: #ffffff;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 10px;
            }
          }
        }
        .sites-item-desc {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          margin-bottom: 20px;
          .sites-item-url-not-avaiable {
            height: 24px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #e31e18;
          }
          .sites-item-dates {
            height: 24px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #53646e;
          }
          .sites-item-domain {
            height: 40px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;

            color: #000000;
          }
          .sites-item-defaultDomain {
            height: 24px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #009933;
          }
        }
        .sites-item-actions-button-preview {
          width: 50px;
          margin-right: 10px;
        }
        .sites-item-actions-button-settings {
          margin-right: 10px;
        }
        .sites-item-actions {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-end;
          justify-content: space-around;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
          .sites-item-actions-button-preview {
            :hover {
              background: #00c141;
              transition: all .4s ease-in-out;
            }
          }
          .sites-item-actions-button-settings {
            :hover {
              background: #00c141;
              transition: all .4s ease-in-out;
            }
          }
          .sites-item-actions-button-edit {
            :hover {
              background: #00c141;
              transition: all .4s ease-in-out;
            }
          }
          .sites-actions-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 9px 12px;
            height: 40px;
            cursor: pointer;
            border: 1px solid #009933;
            box-sizing: border-box;
            border-radius: 4px;
            .site-actions-button-text {
              position: static;
              height: 12px;
              padding-top: 5px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 12px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #19303e;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin-right: 10px;
              margin-left: 10px;
              cursor: pointer;
              .spinner-border {
                top: 0px;
                right: 0px;
                width: 1rem;
                height: 1rem;
                position: inherit;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
    .sites-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      position: fixed;
      bottom: 0px;
      left: 0;
      z-index: 1000;
      right: 0;
      background-color: #dbdbdb;
      padding-bottom: 10px;
      padding-top: 10px;
      .sites-pagination-next-prev-buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-content: stretch;
        flex-wrap: wrap;
        width: 100px;
        .page-nav-next-page {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 13px;
          background: transparent;
          height: 33px;
          border: 1px solid #19303e;
          box-sizing: border-box;
          border-radius: 4px;
          transform: matrix(1, 0, 0, 1, 0, 0);
        }
        .page-nav-prev-page {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 13px;
          background: transparent;
          height: 33px;
          border: 1px solid #19303e;
          box-sizing: border-box;
          border-radius: 4px;
          transform: matrix(1, 0, 0, 1, 0, 0);
        }
        .page-nav-prev-page.disabled {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 13px;

          width: 33px;
          height: 33px;
          background: transparent;
          color: #8c979f;
          border: 1px solid #8c979f;
          box-sizing: border-box;
          border-radius: 4px;
        }
        .page-nav-next-page.disabled {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 13px;

          width: 33px;
          height: 33px;
          background: transparent;
          color: #8c979f;
          border: 1px solid #8c979f;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
      .rows-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #19303e;
        padding-left: 10px;
      }
      .count-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #19303e;
      }
      .rows-number {
        padding-top: 5px;
        .rows-select-number {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: transparent;
          line-height: normal;
          padding-right: 0px;
          width: 49px;
          border: 1px solid #19303e !important;
          box-sizing: border-box;
          border-radius: 4px;
          -webkit-appearance: menulist;
        }
      }
    }
  }
}
