.table-pagination {
  display: flex;
  justify-content: flex-end;
  line-height: 34px;
  height: 50px;
  padding: 0 10px 20px;
}

.page-nav {
  border-radius: 50% !important;
  border: none;
  background-color: transparent;
  outline: none;
  height: 34px;
  width: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.page-nav:hover {
  background-color: transparent;
}

.page-nav.disabled {
  opacity: 0.5;
  cursor: default;
}
.rows-text {
  margin-right: 5px;
}
.count-text {
  margin-left: 20px;
}
.sort-column {
  margin-left: 5px;
}
.list-table-container td .listing-thumb-integration span img {
  border-radius: 0px !important;
  display: block !important;
  width: 100%;
  max-width: 40px;
}
.body-content-header {
  width: 100%;
  padding: 0 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 4rem;
}
.list-header .list-columns {
  position: relative;
  display: inline-block;
  width: 200px;
  vertical-align: middle;
}
.list-header .list-columns .save-columns {
  position: absolute;
  right: 198px;
  vertical-align: middle;
  top: 0;
  margin-right: 10px;
}
.edit-canvas {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.pencil {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.body-content-body .scrollable {
    overflow: visible;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: auto !important;
}
.table-responsive {
    overflow-y: visible;
    height: 100%;
}
.list-container .page-section .scrollable>div {
    overflow-y: scroll;
    height: 100%;
}
.body-content-body{
    display: flex;
    height: calc(100vh - 170px);
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    margin-top: 4rem;
}