

.not-checked-row {
  opacity: 50%;
}



.channel-icon {
  z-index: 5;
  width: 25px;
  height: 25px;
}

.available-cell {
  background: #fff;
  text-align: center;
  max-width: 30px;
  min-width: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
}

div .available-cell-selected {
  background: #fff;
  text-align: center;
  max-width: 30px;
  min-width: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
}
.table-lowligth .available-cell-selected {
  background: rgb(255, 255, 255);
  z-index: 100;
  opacity: 1;
  font-weight: bold;
  max-width: 30px;
  min-width: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  border: 2px solid blue;
}

.available-cell:hover {
  background-color: rgb(224, 240, 245);
}

.selected-cell {
  background: rgb(141, 169, 180);
  max-width: 30px;
  min-width: 30px;
  user-select: none;
}

table tbody tr {
  position: relative;
  max-height: 30px;
  min-height: 30px;
  user-select: none;
}

table thead tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table tbody th {
  position: sticky;
  left: 0;
  background: rgb(255, 255, 255);
  z-index: 1;
  border: 0.5px solid black;
  max-height: 30px;
  min-height: 30px;
  user-select: none;
}

.booked-cell {
  background-color: lightgreen;
  position: relative;
  text-align: center;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  user-select: none;
}

.booked-cell .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(121, 121, 121);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  transition-delay: 0.4s;
}

.booked-cell:hover .tooltiptext {
  visibility: visible;
}
.booked-cell:hover {
  background-color: rgb(101, 185, 101);
  cursor: default;
}

.unavailable-cell {
  background-color: lightgrey;
  position: relative;
  border: 0.5px solid black;
  max-width: 30px;
  min-width: 30px;
  max-height: 20px;
  min-height: 30px;
  cursor: default;
  text-overflow: clip;
}
.unavailable-cell:hover {
  background-color: rgb(172, 172, 172);
  cursor: default;
}

.unavailable-cell .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: rgb(121, 121, 121);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  word-wrap: break-word;

  /* Position the tooltip */
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  transition-delay: 0.4s;
}

.unavailable-cell:hover .tooltiptext {
  visibility: visible;
  overflow: unset;
}

.no-calendar {
  background-color: rgb(255, 198, 177);
}

.last-booked-row {
  background-color: rgb(247, 255, 239);
  text-align: right;
  position: relative;
}

.last-booked-row:hover {
  background-color: rgb(241, 255, 227);
  text-align: right;
  position: relative;
}

.last-booked-row .tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: rgb(235, 235, 235);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  word-wrap: break-word;

  /* Position the tooltip */
  position: absolute;
  z-index: 4;
  top: 100%;
  right: 0%;
  margin-left: -60px;
  transition-delay: 0.4s;
}
.last-booked-row:hover .tooltiptext {
  visibility: visible;
  overflow: unset;
}

.search-term {
  background-color: rgb(190, 187, 139);
}

.multiunits {
  background-color: rgb(235, 233, 206);
  text-align: right;
}

.singleunit {
  background-color: rgb(189, 183, 107);
}
