.users-control-tab {
    display: flex;
    width: 100%;
    height: 100vh;
    flex: 1;
}

.single-user-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 50px 122px;
    box-sizing: border-box;
}

.go-back-icon-border {
    display: flex;
    background: rgba(25, 48, 62, 0.08);
    border-radius: 999px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
}

.go-back-icon-holder {
    width: 40px;
}

.single-user-avatar {
    background: #A3B8C6;
    width: 56px;
    height: 56px;
    border-radius: 50px/50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin-right: 0px !important;
}

.single-user-avatar-label {
    margin-bottom: 0 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22.75px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0 !important;
}

.single-user-email-name-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
    margin-left: 16px;
}

.single-user-names {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
    margin-bottom: 0 !important;
}

.single-user-email {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(25, 48, 62, 0.8);
    margin-right: 70px;
}

.activate-deactivate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 228px;
    height: 40px;
    background: rgba(25, 48, 62, 0.04);
    border-radius: 2px;
    margin-right: 0px !important;
    padding-top: 3px;
}

.activate-the-user {
    width: 55px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #19303E;
    margin-top: -2px;
    margin-bottom: 0;
}

.deactivate-the-user {
    width: 55px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: rgba(25, 48, 62, 0.48);
    margin-right: 16px;
    margin-top: -2px;
    margin-bottom: 0;
}

.user-delete-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    width: 137px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
    margin-left: 16px !important;
    cursor: pointer;
}

.user-delete-btn:hover {
    border: 1px solid #19303E;
}

.user-delete-btn-icon {
    margin-left: 5px;
    cursor: pointer;
}

.user-delete-btn-text {
    position: static;
    width: 79px;
    height: 14px;
    left: calc(50% - 79px/2 + 13px);
    top: calc(50% - 14px/2);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #19303E;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}

.separating-line {
    position: static;
    height: 1px;
    left: 0px;
    top: 0px;
    background: rgba(25, 48, 62, 0.16);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 40px;
    margin-bottom: 24px;
}

.separating-line-bottom {
    position: static;
    height: 1px;
    left: 0px;
    top: 0px;
    background: rgba(25, 48, 62, 0.16);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 40px;
    margin-bottom: 40px !important;
}

.second-separating-line-bottom {
    position: static;
    height: 1px;
    left: 0px;
    top: 0px;
    background: rgba(25, 48, 62, 0.16);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 40px;
    margin-bottom: 5px !important;
}

.single-user-settings-header {
    display: flex;
}

.single-user-role-select {
    margin-left: 25px;
    width: 244px;
    height: 98px;
}

.single-user-select-header {
    position: relative;
    top: 0px;
    left: 0px;
    width: 68px;
    height: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    color: rgba(25, 48, 62, 0.48);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 16px;
}

.single-user-permission {
    width: 165px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #19303E;
    margin-bottom: 10px;
}

.single-user-permission-header {
    width: 37px;
    height: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: #19303E;
    margin-left: 7px;
    margin-right: 7px;
}

.outer-active-radio-button {
    width: 16px;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 1.8px;
    margin-right: 18px;
    margin-left: 16px;
    border-radius: 999px;
    background: #FFFFFF;
    border: 1px solid #00C141;
    display: flex;
    cursor: pointer;
}

.outer-inactive-radio-button {
    width: 16px;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 1.8px;
    margin-right: 18px;
    margin-left: 16px;
    border-radius: 999px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24);
    display: flex;
    cursor: pointer;
}

.radio-button-permissions {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: -20px !important;
    cursor: pointer;
}

.assigning-to-properties-header {
    height: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: rgba(25, 48, 62, 0.48);
    margin-left: 25px;
}

.assigned-property-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    background: #EBFAF0;
    border-radius: 2px;
    height: 60px;
}

.not-assigned-property-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    background: rgba(25, 48, 62, 0.04);
    border-radius: 2px;
    height: 60px;
}

.label-of-assigned-property {
    position: static;
    max-width: 250px;
    left: calc(50% - 44px/2 + 8px);
    top: calc(50% - 20px/2);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #19303E;
    margin: 0px 8px;
}

.the-permissions-info {
    cursor: pointer;
}

.single-user-role-and-permissions-header {
    height: 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #19303E;
    margin-bottom: 31px;
    margin-left: 25px;
    margin-top: -8px;
}

.single-user-role-and-permissions-header-closed {
    cursor: pointer;
    height: 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #19303E;
    margin-top: 10px;
}

.the-dropdown-close {
    display: flex;
    background: rgba(25, 48, 62, 0.08);
    border-radius: 999px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 24px;
    margin-top: -10px;
    margin-right: 5px;
}

.the-dropdown-open {
    cursor: pointer;
    padding: 5px;
    margin-right: -12px;
}

.border-box-of-the-roles-permissions {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    height: 58px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    margin: 40px 0px;
}

.border-box-of-the-properties-access {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    height: 58px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    margin-top: -20px;
}

.single-user-properties-access-header {
    height: 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #19303E;
    margin-left: 25px;
    margin-top: -3px;
}

.assigned-properties-box {
    margin-left: 25px;
    flex-wrap: wrap;
}

.single-user-permission-header-holder {
    width: 154px;
}

.modal-styles {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    padding: 50px;
    width: 512px;
    height: 290px;
    padding-top: 27px;
    z-index: 1000;
}

.overlay-styles {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.the-deleting-user-paragraph {
    position: absolute;
    width: 480px;
    height: 52px;
    left: 16px;
    top: 118px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #19303E;
}

.the-deleting-user-label {
    position: absolute;
    width: 136px;
    height: 30px;
    left: 188px;
    top: 64px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
    white-space: nowrap;
}

.buttons-in-delete-user-modal {
    position: absolute;
    left: 28%;
    top: 75%;
    white-space: nowrap;
}

.close-button {
    position: relative;
    left: 25rem;
    top: 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    color: #19303E;
    font-size: 1.8rem;
    margin-top: 0;
    font-weight: normal;
}

.inner-active-radio-button {
    width: 10px;
    height: 10px;
    background: #00C141;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 999px;
    margin: auto;
    position: relative;
}

.outer-inactive-radio-button .inner-active-radio-button {
    display: none;
}

.single-user-roles-dropdown {
    padding: 0.5rem;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24) !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    height: 40px;
    -webkit-appearance: auto !important;
    appearance: 1 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
}

.single-user-roles-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    min-width: 302px;
    height: 43px;
}

.single-user-roles-wrapper:hover {
    border: 1px solid #19303E;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
}

.single-user-roles-wrapper:focus-within {
    border: 0.5px solid #19303E;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
}

.create-mandatory-margin {
    margin-right: 40px;
    align-items: center;
}

.roles-info-container {
    z-index: 1;
    position: absolute;
    visibility: hidden;
    margin-left: 230px;
    margin-top: 43px;
    background: #F6F7F7;
    box-shadow: 0px 0px 2px #19303E, 0px 4px 8px #19303E;
    border-radius: 2px;
    width: 170px;
}

.roles-info-container label {
    margin: 6px 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #19303E;
    text-align: center;
}

.the-permissions-info {
    position: relative;
}

.the-permissions-info:hover~.roles-info-container {
    visibility: visible;
}

.workspace-user-save-btn-failed-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 169px;
    height: 40px;
    background: #00C141;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
}

.workspace-user-save-btn-failed-submit label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 0 !important;
}

.workspace-user-saving-btn {
    background: #00C141;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 42.5px;
    width: 162px;
    height: 40px;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
    justify-content: center;
}
  
.workspace-user-saving-btn label {
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
}

.workspace-user-saving-btn i {
    margin: 1px 0px 0px 15px;
    font-size: 15px;
    color: #FFFFFF;  
}
  
.workspace-user-saving-btn img {
    margin: 1px 0px 0px 15px;
    font-size: 15px;
    color: #FFFFFF;
}