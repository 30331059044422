.table-container {
    overflow: auto;
    height: 720px;
    width: 100%;
}

.multicalendar-table {
    max-width: 1600px;
    min-width: 600px;
    margin: auto;
    border: 0.5px solid black;
}

.multicalendar-table td {
    border: 0.5px solid black;
    user-select: none;
}

.listing-head,
.table-headers {
    position: sticky;
    top: -1px;
    z-index: 1;
    width: 25vw;
    background: rgb(241, 239, 239);
    border: 0.5px solid black;
}

.table-lowligth td {
    opacity: 0.4;
}

.table-lowligth th {
  opacity: 0.5;
}