
.list-table-container th {
    line-height: 30px;
    white-space: nowrap;
    text-transform: uppercase;
    color: #777;
}
.list-table-container td .listing-thumb-listing span img {
    border-radius: 0px !important;
    display: block !important;
    width: 100%;
    max-width: 70px;
}
.list-table-container td .listing-thumb-guest {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.list-table-container td .listing-thumb-listing {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.list-table-container td .listing-thumb-guest span {
    overflow: hidden;
    display: block !important;
    margin-right: 12px;
    flex-direction: row;
    border-radius: 50% !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background: #FFFFFF;
    margin-bottom: 0;
    box-shadow: 0 4px 9px 0 rgb(74 74 74 / 26%);
    min-width: 40px;
}
.list-table-container td .listing-thumb-listing span {
    border-radius: 4px !important;
    overflow: hidden;
    min-width: 70px;
    display: block !important;
    margin-right: 12px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 4px 9px 0 rgb(74 74 74 / 26%);
    margin-bottom: 0;
}
.list-table-container td .listing-thumb-guest {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.list-table-container td .listing-thumb-guest span img {
    border-radius: 0px !important;
    display: block !important;
    width: 100%;
    max-width: 40px;
}