body {
    background-color:#FBFBFB;
}

.the-whole-sign-up-page {
    display: flex;
    width: 100%;
    height: 100vh;
}

.left-side-of-sign-up {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 25px 40px;
    min-width: 920px;
}

.sign-up-page-image {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/image-sign-up.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
}

.sign-up-mid-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sign-up-header-text {
    width: 123px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #19303E;
    padding-top: 30px;
    margin-right: 280px;
}

.sign-up-with-google {
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;
    margin-top: 60px;
    width: 403px;
    height: 48px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
}

.google-logo {
    margin-left: 27px;
    margin-right: 82.5px;
}

.sign-up-with-facebook {
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;
    width: 403px;
    height: 48px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
}

.facebook-logo {
    margin-right: 72px;
    margin-left: 27px;
}

.sign-up-with-linkedin {
    cursor: pointer;
    display: flex;
    margin-bottom: 24px;
    width: 403px;
    height: 48px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
}

.linkedin-logo {
    margin-left: 28px;
    margin-right: 79px;
}

.sign-up-with-label {
    cursor: pointer;
    margin-top: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #19303E;
}

.delimiter-line-left {
    width: 160px;
    height: 1px;
    background: rgba(25, 48, 62, 0.16);
    margin-top: 14px;  
}

.between-delimiter-line {
    width: 20px;
    height: 20px;
    margin-top: 4px;
    margin-left: 31px;
    margin-right: 32px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(25, 48, 62, 0.48);
}

.delimiter-line-right {
    width: 160px;
    height: 1px;
    background: rgba(25, 48, 62, 0.16);
    margin-top: 14px;
}

.sign-up-email-address {
    width: 128px;
    height: 16px;
    margin-top: 24px;
    margin-right: 270px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: rgba(25, 48, 62, 0.96);
}

.input-sign-up-email-address {
    width: 403px;
    height: 40px;
    margin-top: 8px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 24px;
}

.invalid-registration-input {
    background-color: linear-gradient(0deg, rgba(224, 9, 9, 0.02), rgba(224, 9, 9, 0.02)), #FFFFFF;
    border: 1px solid #E00909 !important;
    box-shadow: none !important;
}

.invalid-registration-input-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #E00909;
    margin-top: -18px;
    margin-bottom: 8px;
}

.sign-up-button {
    width: 403px;
    height: 40px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: white;
    text-align: center;
    cursor: pointer;
}

.sign-up-button-disabled {
    width: 403px;
    height: 40px;
    background: rgba(25, 48, 62, 0.08);
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: rgba(25, 48, 62, 0.32);
    text-align: center;
    cursor: not-allowed;
}

.sign-up-button:hover {
    cursor: pointer;
    background: #00C141;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}

.text-inside-sign-up-button {
    position: relative;
    font-family: Poppins;
    font-style: normal;
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    top: 10px;
}

.text-inside-sign-up-button-disabled {
    position: relative;
    font-family: Poppins;
    font-style: normal;
    color: rgba(25, 48, 62, 0.32);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: not-allowed;
    top: 10px;
}

.text-below-sign-up-button {
    margin-top: 17px;
    width: 408px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(25, 48, 62, 0.64);
    padding-bottom: 35px;
}

.welcome-on-board-text {
    position: absolute;
    width: 281px;
    height: 128px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;
    color: #FFFFFF;
}

.error-try-again-holder {
    display: flex;
    justify-content: center;
}

.error-try-again-box {
    width: 32px;
    height: 32px;
    background: #E00909;
    border-radius: 2px;
}

.error-try-again-icon {
    margin-top: 4px;
    margin-left: 4px;
}

.error-try-again-text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 307px;
    background: #475965;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: white;
    margin-left: 2px;
}

.close-error-try-again {
    cursor: pointer;
    font-size: 1.5rem;
}

@media screen and (max-width: 1200px) {
    .welcome-on-board-text {
        display: none;
    }
}