.find-menu {
    position: fixed; 
    z-index: 100;
    padding: 30px;
}
  
@media screen and (min-width: 1360px) {
    .find-menu {
        top: 50px; 
        left: 352px; 
        width: 26%;
        height: 400px;
    }
}
  
@media screen and (min-width: 380px) and (max-width: 1360px) {
    .find-menu {
        top: 50px; 
        left: 352px; 
        width: 26%;
        height: 400px;
    }
}
  
@media screen and (min-width: 301px) and (max-width: 380px) {
    .find-menu {
        top: 0px; 
        left: 0px; 
        width: 80%;
        height: 100%;
    }
    .guests-filter {
        width: 50px;
    }
}
  
@media screen and (min-width: 100px) and (max-width: 300px) {
    .find-menu {
        top: 0px; 
        left: 0px; 
        width: 95%;
        height: 100%;
    }
    .guests-filter {
        width: 20px;
    }
}