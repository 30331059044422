.header-box-item {
  margin: 2px;
  padding: 2px;
  
}

@media screen and (min-width: 200px) and (max-width: 700px)  {
  .header-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: left;
    align-content: stretch;
    margin-bottom: 10px;
  }

}


@media screen and (min-width: 700px) and (max-width: 1000px) {
  .header-box {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    margin-bottom: 20px;

  }
}

@media screen and (min-width: 1000px)  {
  .header-box {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    margin-bottom: 20px;

  }
}