.object-page-container input[type=text],
.object-page-container input[type=password],
.object-page-container input[type=number],
.object-page-container input[type=text],
.object-page-container textarea,
.object-page-container select {
    width: 100%;
    line-height: 20px;
    height: 34px;
    padding: 5px 10px;
    background-color: inherit;
    border: 1px solid #c2cad8;
    border-radius: 4px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.object-page-container textarea {
    height: initial;
    resize: vertical;
    min-height: 150px;
}

.object-page-container input[type=radio] {
    width: 20px;
    margin: 0 5px 0 0;
    box-shadow: none;
}

.object-page-container label {
    display: inline-flex;
    line-height: 34px;
}

.fs-button {
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #444;
    color: #444;
    border-radius: 4px !important;
    background-color: transparent;
    outline: none;
}

.fs-button:hover {
    background-color: #fafafa;
}

.fs-button.primary {
    color: #fff;
    background-color: #18B156;
    border-color: #18B156;
}

.fs-button.primary:hover {
    background-color: #0F8A40;
    border-color: #0F8A40;
}

.fs-button.success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.fs-button.success:hover {
    background-color: #449d44;
    border-color: #398439;
}

.fs-button.danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.fs-button.danger:hover {
    background-color: #c9302c;
    border-color: #ac2925;
}

.fs-button.info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}

.fs-button.info:hover {
    background-color: #31b0d5;
    border-color: #269abc;
}

.fs-button.in-progress {
    /*    background: url(/assets/layouts/layout/img/loading.gif) no-repeat center; */
    color: transparent !important;
    cursor: default;
}

.checkbox-label {
    line-height: 20px;
}

span.checkbox {
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid #E0E0E0;
    margin: 3px;
    border-radius: 2px !important;
}

span.checkbox.checked {
    border: none;
    background-color: #2dbb52;
    position: relative;
}

span.checkbox.checked .checked-mark {
    left: 5px;
    top: 1px;
    width: 7px;
    height: 11px;
    position: absolute;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

span.select-all {
    margin-left: -0px;
}

.object-page-container .form-control {
    width: 100% !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1) !important;
    border: none !important;
    height: 40px !important;
}

ul.country-list {
    width: 320px !important;
}

.country-highlight {
    background-color: #abe3b9;
}

.flag-dropdown {
    background-color: transparent !important;
    border: none !important;
}

.country-list::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: transparent;
}

.country-list::-webkit-scrollbar {
    width: 10px;
}

.country-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2dbb52;
}

.rccs__card--front {
    border-radius: 10px !important;
}

.rccs__card--back {
    border-radius: 10px !important;
}

.image-upload {
    border-radius: 4px !important;
    border: 1px solid #c2cad8;
    height: 34px;
    padding-top: 5px;
}

.select-w-100 {
    width: 100px;
}

.select-w-120 {
    width: 120px;
}

.select-w-145 {
    width: 145px;
}

.select-w-170 {
    width: 170px;
}

.modified-role-label {
    position: absolute;
    margin-left: 208px;
    margin-top: 11px;
    padding: 4px 10px;
    width: 70px;
    height: 20px;
    background: rgba(154, 196, 236, 0.24);
    border: 1px solid #9AC4EC;
    box-sizing: border-box;
    border-radius: 40px;
}

.modified-role-label-text {
    position: static;
    width: 50px;
    height: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: #19303E;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: -0.7px -0.7px;
}
