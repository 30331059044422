.modal-dialog {
  display: flex;
  max-width: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.modal-body {
  display: flex;
}
#link {
  margin-right: 30px;
}
