.reset-your-password-header-text {
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #19303E;
    margin-right: 85px;
}

.forgotten-password-send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    min-height: 40px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    cursor: pointer;
    color: white;
    margin-right: 260px;
}

.forgotten-password-send-btn:hover {
    cursor: pointer;
    background: #00C141;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}

.forgotten-password-send-btn-disabled {
    width: 160px;
    height: 40px;
    background: rgba(25, 48, 62, 0.08);
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: rgba(25, 48, 62, 0.32);
    text-align: center;
    margin-right: 260px;
    cursor: not-allowed;
}

.forgotten-password-send-btn-failed-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 40px;
    background: #00C141;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    margin-right: 255px;
}
  
.forgotten-password-send-btn-failed-submit label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 0 !important;
}

.forgotten-password-sending-btn {
    background: #00C141;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 42.5px;
    width: 162px;
    height: 40px;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
    margin-right: 260px;
    justify-content: center;
}
  
.forgotten-password-sending-btn label {
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
}

.forgotten-password-sending-btn i {
    margin: 1px 0px 0px 15px;
    font-size: 15px;
    color: #FFFFFF;  
}
  
.forgotten-password-sending-btn img {
    margin: 1px 0px 0px 15px;
    font-size: 15px;
    color: #FFFFFF;
}