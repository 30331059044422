.app-aside {
    width: 210px;
    min-width: 210px;
    margin-left: 32px;
    margin-right: 32px;
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}

.app-aside--logo {
    background: #FFFFFF;
    box-shadow: 0 2px 32px 0 rgba(0,0,0,0.04);
    border-radius: 8px;
    width: 122px;
    min-width: 122px;
    height: 122px;
    min-height: 122px;
    margin-bottom: 16px;
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.app-aside-title {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    margin: 0 0 32px 0;
    padding: 0;
    a {
        color: black;
        &:hover {
            text-decoration: none;
            color: #2DBB52;
        }
    }
}

.app-aside--menu {
    padding: 0;
    list-style: none;
    margin: 16px -52px 0 0;
    li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        a {
            font-weight: 600;
            font-size: 14px;
            color: #32385A;
            letter-spacing: 0;
            text-align: left;
            line-height: 38px;
            display: block;
            width: 100%;
            padding: 0 10px;
            i {
                margin-right: 8px;
                font-size: 18px;
            }
        }
        &.active {
            a {
                background: #FFFFFF;
                border-radius: 4px 0 0 4px;        
            }
        }
    }
}
