body {
    background-color:#FBFBFB;
}

.thanks-for-signing-up-header-text {
    width: 363px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #19303E;
    margin-right: 167px;
}

.thanks-for-signing-up-details {
    width: 446px;
    margin-right: 80px;
    margin-top: 16px;
    height: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(25, 48, 62, 0.64);
}

.thanks-for-signing-up-input-container {
    margin-top: 40px;
}

.thanks-for-signing-up-names-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 525px;
}
  
.thanks-for-signing-up-first-name {
    width: 255px;
}
  
.thanks-for-signing-up-first-name label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: rgba(25, 48, 62, 0.96);
    margin-bottom: 8px;
}
  
.thanks-for-signing-up-first-name input {
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #19303E;
}
  
.thanks-for-signing-up-last-name {
    width: 255px;
}
  
.thanks-for-signing-up-last-name label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: rgba(25, 48, 62, 0.96);
    margin-bottom: 8px;
}
  
.thanks-for-signing-up-last-name input {
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #19303E;
}
  
.thanks-for-signing-up-phone {
    width: 403px;
    margin-right: 123px;
    margin-top: 12px;
}
  
.thanks-for-signing-up-phone label {
    width: 128px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: rgba(25, 48, 62, 0.96);
}
  
.thanks-for-signing-up-phone input {
    width: 275px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #19303E;
}

.accept-the-terms-container {
    margin-top: 24px;
}

.accept-the-terms-text {
    width: 320px;
    height: 38px;
    margin-left: 8px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: rgba(25, 48, 62, 0.64);
}

.set-password-workspace-page-image {
    display: flex;
    flex: 1;
    height: 100%;
}

.cursor-pointer-checkbox {
    cursor: pointer;
}

.something-went-wrong-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #E00909;
    margin-top: 20px;
    margin-right: 320px;
}