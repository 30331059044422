.page-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin: 0px;
  padding: 0px;
  position: relative;
}
.customize-view {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin: 0 20px;
  height: calc(100vh - 180px);
}
.choose-filters {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
}
.filters-wrapper {
  height: calc(100vh - 230px);
}
.list-filters-section {
  overflow: hidden;
  display: flex;
  width: 100%;
  overflow-x: auto !important;
}
.list-filters-section > div {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: content-box;
}
.scrollable {
  overflow: hidden;
  display: flex;
  width: 100%;
  overflow-x: auto !important;
}
.scrollable > div {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: content-box;
}
select {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px !important;
    line-height: 40px;
    height: 40px;
    padding: 0 8px;
    font-size: 14px;
    color: #7B7B7B;
    letter-spacing: 0;
    text-align: left;
    line-height: 22px;
}
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}
/* .row{
    display:block;
} */
h3 {
    margin: 10px 20px;
}

input, select {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    line-height: 40px;
    height: 40px;
    padding: 0 8px;
    font-size: 14px;
    color: #7B7B7B;
    letter-spacing: 0;
    text-align: left;
    line-height: 22px;
}

.edit-update-listing-view .view-actions {
    
    padding: 16px;
    box-shadow: 0 -2px 12px 0 rgb(0 0 0 / 5%);
    background:inherit;
    position: relative;
    z-index: 1000;
    margin-top:20px;
}
