.deactivated-account-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  background: #009933;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.deactivated-account-button label {
  cursor: pointer;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
  padding: 3px;
}

.deactivated-account-button:hover {
  cursor: pointer;
  background: #00C141;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}