.body-wrapper {
  .templates {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    margin-left: 50px;
    overflow-y: scroll;
    height: 912px;
    .card {
      width: 30%;
      margin: 5px;
    }
  }
}
.modal-body {
  display: flex;
  align-items: center;
  .input-group-text {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-top:8.5px;
    padding-bottom:8.5px;
  }
}
