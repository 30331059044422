body {
    background-color:#FBFBFB;
}

.set-your-password-header-text {
    width: 299px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #19303E;
    margin-right: 125px;
}

.sign-up-password-header {
    width: 128px;
    height: 16px;
    margin-top: 60px;
    margin-right: 285px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: rgba(25, 48, 62, 0.96);
}

.password-input-container-sign-up {
    margin-right: 22px;
}

.input-sign-up-password {
    width: 403px;
    height: 40px;
    margin-top: 8px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 45px;
}

.hidden-password-icon {
    margin-left: -79px;
    cursor: pointer;
}

.sign-up-password-details-text {
    margin-bottom: 60px;
    width: 370px;
    height: 32px;
    margin-top: 16px;
    margin-right: 32px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(25, 48, 62, 0.64);
}

.set-your-password-next-button {
    width: 160px;
    height: 40px;
    margin-top: 60px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: white;
    text-align: center;
    cursor: pointer;
    margin-right: 250px;
}

.set-your-password-next-button-disabled {
    width: 160px;
    height: 40px;
    margin-top: 60px;
    background: rgba(25, 48, 62, 0.08);
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: rgba(25, 48, 62, 0.32);
    text-align: center;
    cursor: not-allowed;
    margin-right: 250px;
}

.set-your-password-next-button:hover {
    cursor: pointer;
    background: #00C141;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}

.text-inside-set-your-password-next-button {
    position: relative;
    color: white;
    top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.text-inside-set-your-password-next-button-disabled {
    position: relative;
    color: rgba(25, 48, 62, 0.32);
    top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: not-allowed;
}

.invalid-password-input-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #E00909;
    margin-top: 3px;
    margin-bottom: -13px;
}