.billing-page {
    display: flex;
    width: 100%;
    height: 100vh;
    flex: 1;
}

.billing-page-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 50px 100px;
    box-sizing: border-box;
}

.billing-heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E
}

.both-labels {
    display: flex;
}

.my-current-plan {
    margin-top: 39px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;  
    color: rgba(25, 48, 62, 0.48);
}

.my-properties-limit-text {
    position: relative;
    top: -25px;
    margin-right: 46px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    color: rgba(25, 48, 62, 0.48);
}

@media screen and (max-width: 1029px) {
    .my-properties-limit-text {
        margin-top: 55px;
    }
}

.both-border-boxes {
    display: flex;
    flex-wrap: wrap;
}

.border-box-current-plan {
    margin-top: 20px;
    margin-right: 24px;
    flex: 1;
    height: 200px;
    background: white;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    z-index: 0 !important;
}

.plan-icon-and-name {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 38px;
}

.plan-icon-border {
    display: flex;
    background: rgba(0, 193, 65, 0.12);
    border-radius: 999px;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}

.plan-tick {
    width: 18px;
    height: 18px;
}

.plan-name {
    width: 200px;
    height: 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
}

.assistance-text {
    margin-left: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
    max-width: 450px;
    height: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(25, 48, 62, 0.48);
}

.upgrade-plan-btn {
    margin-left: 40px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 16px;
    width: 130px;
    height: 40px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: white;
    cursor: pointer;
}

.upgrade-plan-btn:hover {
    cursor: pointer;
    background: #00C141;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}

.border-box-plan-limit {
    position: relative;
    top: -5px;
    width: 220px;
    height: 200px;
    background: #19303E;
    border-radius: 2px;
    z-index: 0 !important;
}

.plan-limit-text {
    position: relative;
    width: 129px;
    height: 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    margin-left: 46px;
    top: -65px;
    color: white;
    text-align: center;
}

.limit-info {
    cursor: pointer;
    margin-left: 186px;
    margin-top: 16px;
    margin-right: 16px;
}

.big-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 88px;
    height: 88px;
    background: conic-gradient(#00C141 0deg, #00C141 0deg, #354955 0deg, #354955 360deg);
    border-radius: 999px;
    margin-left: 66px;
}

.little-circle {
    position: relative;
    margin-left: 70px;
    top: -84px;
    width: 80px;
    height: 80px;
    background: #19303E;
    border-radius: 999px;
}

.my-properties {
    padding-top: 26px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #FFFFFF;
    z-index: 1000;

}

.my-properties-limit {
    padding-top: 26px;
    height: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.48);
    z-index: 1000;
    margin-left: 3px;
}

.billing-settings-header {
    margin-top: 35px;
    width: 144px;
    height: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    color: rgba(25, 48, 62, 0.48);
}

.group-billing-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 241px;
    height: 166px;
    margin-top: 15px;
}

.payment-method-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    position: static;
    width: 181px;
    height: 40px;
    left: 0px;
    top: 0px;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 2px 0px;
}

.billing-settings-icons {
    position: static;
    left: 8.84%;
    right: 81.22%;
    top: 27.5%;
    bottom: 27.5%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
}

.payment-method {
    position: static;
    width: 123px;
    height: 14px;
    left: calc(50% - 123px/2 + 13px);
    top: calc(50% - 14px/2);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #19303E;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}

.subscription-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    position: static;
    width: 198px;
    height: 40px;
    left: 0px;
    top: 42px;
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 2px 0px;
}

.subscription-details {
    position: static;
    width: 140px;
    height: 14px;
    left: calc(50% - 140px/2 + 13px);
    top: calc(50% - 14px/2);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #19303E;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}

.billing-shipping-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    position: static;
    width: 241px;
    height: 40px;
    left: 0px;
    top: 84px;
    border-radius: 2px;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 2px 0px;
}

.billing-shipping-address {
    position: static;
    width: 183px;
    height: 14px;
    left: calc(50% - 183px/2 + 13px);
    top: calc(50% - 14px/2);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #19303E;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}

.billing-history-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    position: static;
    width: 152px;
    height: 40px;
    left: 0px;
    top: 126px;
    border-radius: 2px;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin: 2px 0px;
}

.billing-history {
    position: static;
    width: 94px;
    height: 14px;
    left: calc(50% - 94px/2 + 13px);
    top: calc(50% - 14px/2);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #19303E;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}