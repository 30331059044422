.homeaway-connection .the-app-sidebar .thumb img {
    margin: 6px;
}

.the-app-sidebar {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
    align-content: center;
    min-width: 240px;
    padding-top: 32px;
}

.app-wrapper {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
    align-content: stretch;
    padding-top: 22px;
    padding-bottom: 120px;
    max-height: 850px;
    .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        margin-right: 108px;
    }
}

.app-right--contain {
    width: 100%;
    min-height: 850px;
    background: #eaebec;
    box-shadow: 0 2px 32px 0 rgba(0,0,0,0.04);
    border-radius: 8px 0 0 0!important;
    padding: 32px;
}

.bookingcom-connection {
    .ha-properties {
        margin-top: 40px;
    }

    .gray-box-bcom {
        background: #F4F6F8;
        border-radius: 4px;
        padding: 16px 24px;
    }

    .app-aside {
        margin-top: 32px;
    }

    .form-bcom-properties {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-end;
        align-content: center;
        padding-bottom: 24px;

        .left {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            width: 100%;
        }

        .hotel-id {
            width: 100%;
            margin-right: 16px;
        }

        .form-group {
            margin-bottom: 0;
        }

        .button {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            margin-left: 16px;
        }
    }

    .bcom-properties-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        .thumb {
            max-width: 78px;
            box-shadow: 0 4px 9px 0 rgba(74,74,74,0.26);
            border-radius: 4px;
            overflow: hidden;
            margin-right: 16px;
            min-height: 55px;
            img {
                width: 100%;
                max-width: 100%;
                display: block;
            }
        }
        h4 {
            margin: 0;
            padding: 0;
        }

        p {
            margin: 0;
            padding: 0;
        }
    }
}

.main-title {
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0.3px;
    text-align: left;
    line-height: 36px;
    margin-bottom: 24px;
    margin-top: 0;
}

.bcom-the-mapping {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;
    align-content: flex-start;
    margin-top: 10px;

    h4 {
        margin-bottom: 0;
    }
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: center;

            .thumb {
                background: #FFFFFF;
                border-radius: 4px;
                overflow: hidden;
                margin-right: 8px;
                max-width: 44px;
                img {
                    width: 100%;
                    max-width: 100%;
                    display: block;
                }
            }

            p {
                font-size: 14px;
                color: #32385A;
                letter-spacing: 0;
                line-height: 22px;
                font-weight: normal;
                margin: 0;
                padding: 0;
            }

            .listing {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                margin-bottom: 2px;
            }

            .mapped-listing {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                background: #32385A;
                border-radius: 4px;
                color: white;
                width: 100%;
                position: relative;

                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                i {
                    margin-left: 4px;
                }

                .thumb {
                    max-width: 44px;
                    margin: 2px 5px;
                }

                p {
                    font-size: 14px;
                    color: white;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: normal;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .left-mapping {
        width: 49%;
        ul {
            background: #FFFFFF;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.09);
            border-radius: 4px;
            width: 100%;
            overflow-y: scroll;
            max-height: 500px;
            padding: 10px;
            li {
                background: #EEEEEE;
                border: 1px dashed #8E8E8E;
                border-radius: 4px;
                padding: 2px;
                &:hover {
                    text-decoration: none;
                }

                &.has-mapped {
                    border: 1px solid #32385a;
                }
                
            }
        }
    }

    .right-mapping {
        width: 49%;
        ul {
            background: #FFFFFF;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.09);
            border-radius: 4px;
            width:100%;
            overflow-y: scroll;
            padding: 10px;
            max-height: 500px;
            li {
                background: #32385A;
                border-radius: 4px;
                padding: 2px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                color: #fff;
                position: relative;
                p {
                    color: #fff;
                }
                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                i {
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }
    }
}

.button {
    font-family: 'Open Sans', sans-serif;
    border-radius: 4px!important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 24px; 
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.5px; 
    text-align: center;
    line-height: 48px;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;

    &.button-inline {
        display: inline-flex;
    }

    &.button-small {
        line-height: 32px;
        padding: 0 16px;
        font-size: 14px;
        letter-spacing: 0.35px;
    }

    &.button-medium {
        line-height: 40px;
        padding: 0 16px;
        font-size: 16px;
        letter-spacing: 0.35px;
    }
    
    &.button-solid {
        background: #2DBB52;
        border: 1px solid #2DBB52;
        color: #FFFFFF;
        box-shadow: 0 4px 8px 0 rgba(45,187,82,0.26);
        &:hover {
            background: #249441;
            border: 1px solid #249441;
            transition: all 0.3s ease;
            text-decoration: none;
            i {
                right: -6px;
                transition: all 0.3s ease;
            }
        }
    }
}
