.back-to-home-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 16px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: white;
    cursor: pointer;
}

.back-to-home-btn:hover {
    cursor: pointer;
    background: #00C141;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}
