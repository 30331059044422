.side-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgb(222, 244, 247);
    z-index: 10001;
    border-left: 1px solid black;
    padding: 30px;
    box-sizing: border-box;
    transition: 0.4s;
}

.hidden-side-menu {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-color: rgb(222, 244, 247);
    opacity: 50%;
    z-index: 10000;
    border-left: 1px solid black;
    padding: 30px;
    transition-delay: 0.3s;
}

.extra-info {
    width: 43%;
}

@media screen and (min-width: 1360px) {
    .side-menu {
        width: 40%;
    }

    .scrollable-content {
        width: 750px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1360px) {
    .side-menu {
        width: 40%;
    }

    .scrollable-content {
        width: 750px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
    .side-menu {
        width: 40%;
    }

    .scrollable-content {
        width: 750px;
    }
}

@media screen and (min-width: 640px) and (max-width: 800px) {
    .side-menu {
        width: 50%;
    }

    .scrollable-content {
        width: 99%;
    }
}

@media screen and (min-width: 560px) and (max-width: 640px) {
    .side-menu {
        width: 60%;
    }

    .scrollable-content {
        width: 99%;
    }
}

@media screen and (min-width: 300px) and (max-width: 560px) {
    .side-menu {
        width: 100%;
    }

    .scrollable-content {
        width: 99%;
    }
}

.scrollable-content {
    overflow: auto;
    height: 85%;
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 2%;
    left: 0%;
    transform: translate(0%, -50%);
    background: none;
    border-radius: 80px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

input:checked + .slider {
    background-color: rgb(16, 185, 58);
}

input:focus + .slider {
    box-shadow: 0 0 1px rgb(16, 185, 58);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
