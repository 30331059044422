.side-menu {
    position: fixed;
    top: 0;
    right: 0;

    height: 100%;
    background-color: rgb(222, 244, 247);
    z-index: 10001;
    border-left: 1px solid black;
    padding: 30px;
    transition: 0.4s;
}
.hidden-side-menu {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgb(222, 244, 247);
    opacity: 50%;
    z-index: 10000;
    border-left: 1px solid black;
    padding: 30px;
    transition-delay: 0.3s;
}

@media screen and (min-width: 1360px) {
    .side-menu {
        width: 26%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1360px) {
    .side-menu {
        width: 30%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
    .side-menu {
        width: 40%;
    }
}

@media screen and (min-width: 640px) and (max-width: 800px) {
    .side-menu {
        width: 50%;
    }
}

@media screen and (min-width: 560px) and (max-width: 640px) {
    .side-menu {
        width: 60%;
    }
}

@media screen and (min-width: 300px) and (max-width: 560px) {
    .side-menu {
        width: 100%;
    }
}

.side-menu-content {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 2%;
    left: 0%;
    transform: translate(0%, -50%);
    background: none;
    border-radius: 80px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f32121;
    // -webkit-transition: .4s;
    // transition: .4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    // -webkit-transition: .4s;
    // transition: .4s;
}

input:checked + .slider {
    background-color: rgb(16, 185, 58);
}

input:focus + .slider {
    box-shadow: 0 0 1px rgb(16, 185, 58);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
