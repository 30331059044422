#questionnaire-page {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
}

.questionnaire-left-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  min-width: 920px;
}

.questionnaire-mid-section {
  margin: 70px 0px 0px 100px;
}

.questionnaire-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #19303E;
}

.dive-in-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(25, 48, 62, 0.64);
}

.selections-container {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #19303E;
}

.role-options-dropdown {
  background-color: #FFFFFF;
  -webkit-appearance: auto !important;
  appearance: 1 !important;
  height: 28px;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(25, 48, 62, 0.16) !important;
}

.company-actions-dropdown {
  background-color: #FFFFFF;
  -webkit-appearance: auto !important;
  appearance: 1 !important;
  height: 28px;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(25, 48, 62, 0.16) !important;
}

.website-ownership-dropdown {
  background-color: #FFFFFF;
  -webkit-appearance: auto !important;
  appearance: 1 !important;
  height: 28px;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(25, 48, 62, 0.16) !important;
}

.pms-dropdown {
  background-color: #FFFFFF;
  -webkit-appearance: auto !important;
  appearance: 1 !important;
  height: 28px;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(25, 48, 62, 0.16) !important;
}

.selections-row {
  height: 30px;
}

.properties-count-textarea {
  border: none;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  outline: none;
  resize: none;
  width: 40px;
  height: 28px;
  padding: 3px 0px 0px 0px;
  text-align: center;
  color: #00C141;
  margin-left: 10px !important;
}

.website-ownership-textarea {
  height: 28px;
  width: 255px;
  border: none;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  outline: none;
  resize: none;
  padding: 0px 0px 0px 0px;
}

.channel-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: rgba(25, 48, 62, 0.48);
}

.selected-channel-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #19303E;
}

.other-channel-textarea {
  resize: none;
  background: #FFFFFF;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  height: 40px;
  margin-left: 10px;
  outline: none;
  padding: 7px 0px 0px 4px;
}

.questionnaire-main-labels {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #19303E;
}

.questionnaire-secondary-labels {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
}

.interest-box {
  cursor: pointer;
  width: 160px;
  height: 72px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
}

.interest-box:hover {
  border-color: #00E64D;
}

.interest-box-active {
  cursor: pointer;
  width: 160px;
  height: 72px;
  border: 1px solid #00C141;
  box-sizing: border-box;
  border-radius: 2px;
}

.interest-box-active label {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #00C141;
}

.interest-label {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.48);
}

/* .interest-label-active {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #00C141;
} */