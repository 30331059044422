#user-profile-page {
  display: flex;
  width: 100%;
  height: 100vh;
  flex: 1;
}

.profile-tab-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 56px 146px;
  box-sizing: border-box;
}

.user-profile-heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #19303E
}

.user-settings-names {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #19303E;
}

.avatar-container {
  height: 104px;
}

.user-settings-avatar {
  width: 104px;
  height: 104px;
  background: #E3E6E8;
  border-radius: 50px/50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-settings-picture {
  width: 104px;
  height: 104px;
  border-radius: 50px/50px;
}

.user-avatar-initials {
  height: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.user-settings-upload-avatar {
  position: relative;
  bottom: 36px;
  left: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid rgba(25, 48, 62, 0.24);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
  border-radius: 50px/50px;
}

.user-settings-inputs-container {
  padding: 41px 0px;
}

.user-settings-email {
  width: 403px;
  height: 82px;
}

.user-settings-email label {
  width: 128px;
  height: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: rgba(25, 48, 62, 0.48);
  margin-bottom: 0 !important;
}

.user-settings-email input {
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
}

.user-settings-names-container {
  display: flex;
  flex-direction: row;
  width: 403px;
  height: 82px;
  justify-content: space-between;
  margin-top: 15px;
}

.user-settings-first-name {
  width: 194px;
}

.user-settings-first-name label {
  width: 128px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: rgba(25, 48, 62, 0.48);
  margin-bottom: 0 !important;
}

.user-settings-first-name input {
  width: 194px;
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #19303E;
}

.user-settings-last-name {
  width: 193px;
}

.user-settings-last-name label {
  width: 128px;
  height: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: rgba(25, 48, 62, 0.48);
  margin-bottom: 0 !important;
}

.user-settings-last-name input {
  width: 193px;
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;

}

.user-settings-phone {
  margin-top: 15px;
  width: 403px;
  height: 86px;
}

.user-settings-phone label {
  width: 128px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: rgba(25, 48, 62, 0.48);
}

.user-settings-phone input {
  width: 275px;
  height: 40px;
  border: 1px solid rgba(25, 48, 62, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #19303E;
}

.user-profile-save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: #009933;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.user-profile-save-btn-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 40px;
  background: rgba(25, 48, 62, 0.08);
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
  pointer-events: none;
}

.user-profile-save-btn label {
  cursor: pointer;
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}

.user-profile-save-btn-disabled label {
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: rgba(25, 48, 62, 0.32);
  margin-bottom: 0 !important;
  pointer-events: none;
}

.user-profile-save-btn:hover {
  cursor: pointer;
  background: #00C141;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}

.user-profile-saving-btn {
  background: #00C141;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 42.5px;
  width: 142px;
  height: 40px;
  box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
  border-radius: 2px;
}

.user-profile-saving-btn label {
  height: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.user-profile-saving-btn i {
  margin: 1px 0px 0px 15px;
  font-size: 15px;
  color: #FFFFFF;
}

.user-profile-saving-btn img {
  margin: 1px 0px 0px 15px;
  font-size: 15px;
  color: #FFFFFF;
}

.invalid-input-label {
  width: 403px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  color: #E00909;
  margin-top: 5px;
}

.invalid-phone-label {
  width: 403px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  color: #E00909;
  margin: 5px 0px 0px 103px;
}



.slider {
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  height: 22px;
  width: 48px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.invalid-input-style {
  background-color: linear-gradient(0deg, rgba(224, 9, 9, 0.02), rgba(224, 9, 9, 0.02)), #FFFFFF;
  border: 1px solid #E00909 !important;
  box-shadow: 0px 0px 3px red !important;
}

.user-profile-save-btn-failed-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  min-height: 40px;
  background: #00C141;
  box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
  border-radius: 2px;
}

.user-profile-save-btn-failed-submit label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0 !important;
}