.side-menu-custom-stay {
  position: fixed; 
  top: 0; 
  right: 0; 
  height: 100%;
  background-color: rgb(222, 244, 247); 
  z-index: 10000;
  border-left: 1px solid black;
  padding: 30px;
  box-sizing: border-box;
  transition: 0.3s;
}

@media screen and (min-width: 1530px) {
 .side-menu-custom-stay {
  width: 43%;
 }
 .scrollable-content {
  width: 750px;
 }
}

@media screen and (min-width: 1300px) and (max-width: 1529px) {
  .side-menu-custom-stay {
   width: 52%;
  }
  .scrollable-content {
    width: 750px;
   }
 }

 @media screen and (min-width: 1150px) and (max-width: 1300px) {
  .side-menu-custom-stay {
   width: 60%;
  }
  .scrollable-content {
    width: 750px;
   }
 }

 @media screen and (min-width: 1100px) and (max-width: 1150px) {
  .side-menu-custom-stay {
   width: 65%;
  }
  .scrollable-content {
    width: 750px;
   }
 }

 @media screen and (min-width: 820px) and (max-width: 1100px) {
  .side-menu-custom-stay {
   width: 70%;
  }
  .scrollable-content {
    width: 750px;
   }
 }

 @media screen and (min-width: 760px) and (max-width: 820px) {
  .side-menu-custom-stay {
   width: 80%;
  }
  .scrollable-content {
    width: 750px;
   }
 }

 
 @media screen and (max-width: 759px) {
  .side-menu-custom-stay {
   width: 100%;
  }

  .scrollable-content {
    width: 99%;
   }

 }


.hidden-side-menu-custom-stay {
  visibility: hidden;
  position: fixed;
  top: 0; 
  right: 0; 
  width: 50%;
  height: 100%;
  background-color: rgb(222, 244, 247); 
  opacity: 50%;
  border-left: 1px solid black;
  padding: 30px;
  transition: 0.3s;
}


 .scrollable-content {
  overflow: auto;
  height: 85%;
 }

 .select-days-of-week-custom {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #edf6ff;
  background-image: none;
}


 .close-button {
  cursor: pointer;
  position: absolute;
  top: 2%;
  left: 0%;
  transform: translate(0%, -50%);
  background: none;
  border-radius: 80px;
  
}



.table-dafault-rules td {
  width: 80px;
  padding: 5px;
}
.select{
  width: 160px;
}

.min-nights {
  width: 67px;
}