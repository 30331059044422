.edit-site-grid-container {
  display: flex;
  flex-direction: column;
  .sites-breadcrumbs {
    .breadcrumb {
      background-color: inherit;
      .breadcrumb-item {
        display: flex;
        .breadcrumb-link {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          align-items: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: #8c979f;
        }
      }
    }
    .breadcrumb-link-active {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #19303e;
    }
  }
  .edit-site-header {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .edit-site-header-site-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      .edit-site-header-name-header {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 110%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #19303e;
        width: 100%;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
      }
      .edit-site-header-name-action {
        display: flex;
        align-items: center;
        .site-name-input {
          border-radius: 4px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 12px;
          color: #19303e;
        }
      }
      .edit-site-header-toggle-site-name {
        margin-left: 10px;
        margin-bottom: 10px;
        .pointer {
          cursor: pointer;
        }
      }
      .edit-site-header-actions {
        width: 100%;
        display: flex;
        flex-direction:column;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        .edit-site-header-site-status {

          .edit-site-header-site-status-unpublish-container{
          .edit-site-header-site-status-unpublish-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 17px 16px;
            width: 165px;
            height: 48px;
            background: RED;
            border-radius: 5px;
            border: none;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            font-feature-settings: 'liga' off;
            color: #26313b;
            .spinner-border {
              position: inherit;
              right: 0px;
              top: 0px;
              width: 1rem;
              height: 1rem;
            }
          }
          .fs-button:hover {
            background-color: #c71a1a;
            transition: all 0.4s ease-in-out;
          }
          .edit-site-header-site-status-unpublish-button svg {
            margin-right: 10px;
          }
        }
        .edit-site-header-site-status-publish-container{
          .edit-site-header-site-status-publish-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 17px 16px;
            width: 165px;
            height: 48px;
            background: #00c141;
            border-radius: 5px;
            border: none;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            font-feature-settings: 'liga' off;
            color: #26313b;
            .spinner-border {
              position: inherit;
              right: 0px;
              top: 0px;
              width: 1rem;
              height: 1rem;
            }
          }
          .fs-button:hover {
            background-color: green;
            transition: all 0.4s ease-in-out;
          }
          .edit-site-header-site-status-publish-button svg {
            margin-right: 10px;
          }
        }
        }
        .edit-site-header-open-builder {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          .edit-site-header-open-builder-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 17px 16px;
            width: 165px;
            height: 48px;
            background: #00c141;
            border-radius: 5px;
            border: none;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            font-feature-settings: 'liga' off;
            color: #26313b;
            margin-bottom:5px;
            .spinner-border {
              position: inherit;
              right: 0px;
              top: 0px;
              width: 1rem;
              height: 1rem;
            }
          }
          button:hover {
            background: green;
            transition: all 0.4s ease-in-out;
          }
          .edit-site-header-open-builder-button svg {
            margin-right: 10px;
          }
        }
        .edit-site-header-toggle-payment-rules {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          .edit-site-header-toggle-payment-rules-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 17px 16px;
            width: 165px;
            height: 48px;
            background: #00c141;
            border-radius: 5px;
            border: none;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            font-feature-settings: 'liga' off;
            color: #26313b;
            margin-bottom:5px;
          }
          button:hover {
            background: green;
            transition: all 0.4s ease-in-out;
          }
          .edit-site-header-toggle-payment-rules-button svg {
            margin-right: 10px;
          }
        }
      }
      .edit-site-heaeder-site-link {
        cursor: pointer;
        margin-bottom:5px;
        .edit-site-header-site-link-text-published {
          height: 24px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #009933;
        }
        .edit-site-header-site-link-text-notpublished {
          height: 24px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #e31e18;
        }
      }
    }
    .edit-site-header-input-group {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      .edit-site-header-search {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        align-content: center;
        margin-bottom: 10px;
        .edit-site-header-search-header {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          color: #53646e;
        }
        .edit-site-header-search-action {
          width: 100%;
          height: 32px;
          display: flex;
          background-color: #ffffff;
          border-radius: 4px;
          .edit-site-header-search-field-button {
            border: none;
            background: transparent;
            border-radius: 4px;
            .button-search-icon {
              color: #53646e;
            }
          }
          .edit-site-header-search-field-input {
            height: 100%;
            width: 100%;
            border: none;
            border-radius: 4px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #19303e;
          }
          .edit-site-header-search-field-input:focus,
            :focus-visible {
              outline-color: #00c141;
            }
        }
      }
      .edit-site-header-default-account {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;
        .edit-site-header-default-account-header {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #53646e;
        }
        .edit-site-header-default-account-action {
          width: 100%;
          .edit-site-header-default-account-select {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            -webkit-appearance: menulist;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            color: #19303e;
          }
        }
      }
      .edit-site-header-display-listings {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
        .edit-site-header-display-listings-header {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          color: #53646e;
        }
        .edit-site-header-display-listings-action {
          display: flex;
        }
      }
    }
  }
  .edit-site-payment-rules-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    .edit-site-payment-rules-header {
      .edit-site-payment-rules-header-name {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #19303e;
      }
    }
    .edit-site-payment-rules-section {
      display: flex;
      flex-direction: column;
      .edit-site-payment-rule {
        display: flex;
        flex-direction: column;
        .edit-site-payment-rule-charge {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: center;
          .edit-site-payment-rule-charge-header {
            height: 40px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color: #19303e;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin-right: 10px;
          }
          .edit-site-payment-rule-charge-method {
            margin-bottom: 10px;
            .edit-site-payment-rule-charge-amount-select {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: auto;
              height: 32px;
              background: #ffffff;
              border-radius: 4px;
              -webkit-appearance: menulist;
              margin-right: 10px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 12px;
              align-items: center;
              color: #19303e;
            }
          }
          .edit-site-payment-rule-charge-amount {
            display: flex;
            background: #ffffff;
            border-radius: 4px;
            height: 32px;
            .form-control-percent-input {
              height: 32px;
              background: #ffffff;
              border-radius: 4px;
              border: none;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 12px;
              align-items: center;
              color: #19303e;
            }
            .input-group-append {
              padding-left: 2px;
            }
            .input-group-text {
              background: white;
              border: none;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #8c979f;
            }
          }
        }
        .edit-site-payment-rule-time {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          align-content: center;
          flex-wrap: wrap;
          margin-top: 30px;
          .edit-site-payment-rule-time-header {
            height: 40px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color: #19303e;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin-right: 10px;
          }
          .edit-site-payment-rule-time-amount {
            display: flex;
            margin-right: 20px;
            background: white;
            border-radius: 4px;
            height: 32px;
            margin-bottom: 10px;
            .edit-site-payment-rule-time-input {
              .form-control-payment-days {
                height: 32px;
                width: 100px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                background: transparent;
                color: #19303e;
                border: none;
              }
            }
            .input-group-append {
              .input-group-text {
                border: none;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #8c979f;
                background: transparent;
              }
            }
          }
          .edit-site-payment-rule-time-method {
            .payment-time-select {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: auto;
              height: 32px;
              background: #ffffff;
              border-radius: 4px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 12px;
              align-items: center;
              color: #19303e;
              -webkit-appearance: menulist;
            }
          }
        }
        .edit-site-payment-rules-remove-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 17px 24px;
          width: auto;
          height: 48px;
          background: red;
          border-radius: 5px;
          border: none;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          font-feature-settings: 'liga' off;
          color: #26313b;
        }
      }
    }
    .edit-site-payment-rules-footer {
      .edit-site-payment-rules-footer-remove-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 17px 24px;
        width: 83px;
        height: 48px;
        background: #00c141;
        border-radius: 5px;
        border: none;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        font-feature-settings: 'liga' off;
        color: #26313b;
      }
    }
  }
  .edit-site-items-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    .edit-site-item {
      display: flex;
      // width: 1400px;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-bottom: 15px;
      height: 120px;
      background: #f2f2f2;
      border-radius: 4px;
      padding-right: 0px;
      padding-left: 0px;
      .edit-site-item-img {
        width: 100%;
        .edit-site-img {
          height: 180px;
          width: 100%;
          border-radius: 4px;
          object-fit: cover;
          @media (min-width: 600px) {
            height: 300px;
          }
        }
      }
      .edit-site-item-desc {
        display: flex;
        flex-direction: column;
        height: 100px;
        .edit-site-item-name {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: space-between;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          .edit-site-item-name-header label {
            height: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #53646e;
          }
          .edit-site-item-name-header-desc {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            display: flex;
            align-items: center;
            color: #19303e;
          }
        }
        .edit-site-item-channel {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: space-between;
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 20px;
          .edit-site-item-channel-header label {
            height: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #53646e;
          }
          .edit-site-item-channel-header-desc {
            .edit-site-item-channel-header-button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 17px 24px;
              width: auto;
              height: 40px;
              border-radius: 5px;
              background: transparent;
              border: 1px solid #009933;
              box-sizing: border-box;
              border-radius: 4px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              font-feature-settings: 'liga' off;
              color: #26313b;
            }
            .fs-button:hover {
              background-color: green;
              transition: all 0.4s ease-in-out;
            }
            .edit-site-item-channel-header-button svg {
              margin-right: 10px;
            }
          }
        }
      }
      .edit-site-item-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        align-content: center;
        height: 150px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        .edit-site-item-account {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;
          justify-content: center;
          .edit-site-item-account-header {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #53646e;
          }
          .edit-site-item-account-action {
            .edit-site-item-account-select {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 10px 12px;
              background: #ffffff;
              border-radius: 4px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 12px;
              -webkit-appearance: menulist;
              color: #19303e;
            }
          }
        }
        .edit-site-item-listing {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          .edit-site-item-listing-header {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: #53646e;
          }
          .edit-site-item-listing-action {
          }
        }
      }
    }
  }
  .edit-site-save-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 1000;
    right: 0;
    background-color: #dbdbdb;
    padding-top: 10px;
    padding-bottom: 10px;
    .edit-site-clear-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 17px 24px;
      width: auto;
      height: 48px;
      border-radius: 5px;
      background: transparent;
      border: 1px solid #009933;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      font-feature-settings: 'liga' off;
      color: #26313b;
      margin-right: 20px;
    }
    .fs-button:hover {
      background-color: #009933;
      transition: all 0.4s ease-in-out;
    }
    .edit-site-save-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 17px 24px;
      width: auto;
      height: 48px;
      background: #00c141;
      border-radius: 5px;
      border: none;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      font-feature-settings: 'liga' off;
      color: #26313b;
      .spinner-border {
        position: inherit;
        right: 0px;
        top: 0px;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
