@media (max-width: 991.98px) {
  .app-body.body-content-show .body-navleft,
  .app-body.body-content-show .body-sidebar {
    visibility: hidden;
    transform: translateX(-350px);
  }
  .app-body.body-content-show .body-content {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 991.98px) {
  .app-body.body-content-visible .body-content {
    visibility: visible;
    transform: translateX(305px);
    opacity: 1;
  }
}

@media (max-width: 1199.98px) {
  .app-body.body-options-show .body-content {
    transform: translateX(-260px);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .app-body.body-options-show .body-navleft,
  .app-body.body-options-show .body-sidebar {
    transform: translateX(-260px);
  }
}

@media (min-width: 1200px) {
  .app-body.body-options-show .body-content-sidebar {
    opacity: 1;
    visibility: visible;
  }
}

.body-wrapper {
  background-color: inherit;
}
@media (min-width: 992px) {
  .body-wrapper {
    top: 60px;
  }
}

.body-wrapper-two {
  position: relative;
  top: 0;
}
.body-wrapper-two .body-content-header {
  right: 0;
}
.body-wrapper-two .body-content-body {
  right: 0;
}
@media (min-width: 1200px) {
  .body-wrapper-two .body-content-sidebar {
    opacity: 0;
    visibility: hidden;
  }
}

.body-navleft,
.body-sidebar,
.body-content {
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .body-navleft,
  .body-sidebar,
  .body-content {
    transition: none;
  }
}

.body-navleft {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 1px solid rgba(72, 94, 144, 0.16);
  width: 54px;
  padding: 15px 10px;
}
@media (min-width: 992px) {
  .body-navleft {
    width: 60px;
  }
}
.body-navleft .nav-link {
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b2e4b;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .body-navleft .nav-link {
    transition: none;
  }
}
.body-navleft .nav-link:hover,
.body-navleft .nav-link:focus {
  color: #1b2e4b;
}
.body-navleft .nav-link.active {
  color: #0168fa;
}
.body-navleft .nav-link.active svg {
  fill: rgba(1, 104, 250, 0.2);
}
.body-navleft .nav-link + .nav-link {
  margin-top: 10px;
}
.body-navleft .nav-link svg {
  width: 20px;
  stroke-width: 2.2px;
  fill: rgba(27, 46, 75, 0.1);
}

.body-sidebar {
  background-color: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
}
@media (min-width: 480px) {
  .body-sidebar {
    right: auto;
    width: 250px;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
  }
}
@media (min-width: 992px) {
  .body-sidebar {
    width: 265px;
  }
}
@media (min-width: 1200px) {
  .body-sidebar {
    width: 280px;
  }
}

.body-sidebar-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 4rem;
}
.body-sidebar-header .feather-search {
  color: #8392a5;
  align-self: center;
  width: 18px;
  stroke-width: 2.8px;
  margin-right: 10px;
  margin-top: -2px;
}
.body-sidebar-header .search-form {
  flex: 1;
  display: flex;
  align-items: center;
}
.body-sidebar-header .search-form .form-control {
  border-width: 0;
  background-color: transparent;
  font-size: inherit;
  padding: 0;
  color: #1b2e4b;
}

.body-sidebar-body {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: -60px;
  margin-top: 4rem;
}
.body-sidebar-body .tab-pane:first-child .body-list-divider::before {
  width: 27px;
}

.body-list .media {
  color: #1b2e4b;
  padding: 10px;
  align-items: flex-end;
  border-radius: 0.25rem;
}
.body-list .media:hover,
.body-list .media:focus {
  background-color: #f5f6fa;
  cursor: default;
}
.body-list .media:hover .avatar-online::after,
.body-list .media:hover .avatar-offline::after,
.body-list .media:focus .avatar-online::after,
.body-list .media:focus .avatar-offline::after {
  box-shadow: 0 0 0 2px #eff2f7;
}
@media (min-width: 992px) {
  .body-list .media:hover nav,
  .body-list .media:focus nav {
    display: flex;
  }
}
.body-list .media + .media {
  margin-top: 2px;
}
@media (min-width: 480px) {
  .body-list .media.active {
    background-color: #eef0f7;
  }
  .body-list .media.active .avatar-online::after,
  .body-list .media.active .avatar-offline::after {
    box-shadow: 0 0 0 2px #e5e9f2;
  }
}

.body-list .media-body span {
  color: #8392a5;
  display: block;
  line-height: 1.02;
}

.body-list nav {
  display: none;
  align-items: center;
  align-self: center;
}
.body-list nav a {
  display: block;
  color: #8392a5;
}
.body-list nav a:hover,
.body-list nav a:focus {
  color: #1b2e4b;
}
.body-list nav a + a {
  margin-left: 5px;
}
.body-list nav svg {
  width: 16px;
}

.body-list-divider {
  display: flex;
  align-items: center;
  color: #8392a5;
  font-size: 10px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', Roboto, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px -10px 10px;
}
.body-list-divider:first-child {
  margin-top: 0;
}
.body-list-divider::before,
.body-list-divider::after {
  content: '';
  display: block;
  height: 1px;
  background-color: rgba(229, 233, 242, 0.7);
}
.body-list-divider::before {
  margin-right: 5px;
  width: 15px;
}
.body-list-divider::after {
  margin-left: 5px;
  flex: 1;
}

.body-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  transform: translateX(100vw);
  opacity: 0;
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .body-content {
    transition: none;
  }
}
@media (min-width: 480px) {
  .body-content {
    left: 0;
    transform: translateX(320px);
  }
}
@media (min-width: 992px) {
  .body-content {
    left: 325px;
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .body-content {
    left: 280px;
  }
}

.body-content-header {
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  display: flex;
  align-items: center;
  padding: 0 20px;
}
@media (min-width: 1200px) {
  .body-content-header {
    right: 290px;
    padding: 0 25px;
  }
}
.body-content-header .nav-link {
  color: #8392a5;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 0;
}
.body-content-header .nav-link:hover,
.body-content-header .nav-link:focus {
  color: #525f70;
}
.body-content-header .nav-link.active {
  color: #0168fa;
  font-weight: 600;
  position: relative;
}
.body-content-header .nav-link.active::before {
  content: '';
  position: absolute;
  bottom: -19px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #0168fa;
}
.body-content-header .nav-link + .nav-link {
  margin-left: 30px;
}
.body-content-header .nav-link span {
  display: none;
}
@media (min-width: 576px) {
  .body-content-header .nav-link span {
    display: inline;
  }
}

.body-content-body {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (min-width: 1200px) {
  .body-content-body {
    right: 290px;
    display: flex;
    height: calc(100vh - 100px);
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }
}
@media (min-width: 1200px) {
  .body-content-body .tab-pane > .row {
    max-width: 800px;
  }
}

.body-content-sidebar {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -260px;
  bottom: 0;
  border-left: 1px solid rgba(72, 94, 144, 0.16);
  width: 260px;
  padding: 20px;
}
@media (min-width: 1200px) {
  .body-content-sidebar {
    width: 290px;
    padding: 25px;
    right: 0;
  }
}

.body-edit-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-color: #e5e9f2;
  color: #8392a5;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 0 3px #fff;
}
.body-edit-photo:hover,
.body-edit-photo:focus {
  color: #1b2e4b;
  background-color: #c0ccda;
}
.body-edit-photo svg {
  width: 12px;
  stroke-width: 3px;
}

.body-call-nav {
  display: flex;
}
.body-call-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  color: #fff;
}
.body-call-nav a svg {
  width: 16px;
  stroke-width: 2.6px;
}
.body-call-nav a + a {
  margin-left: 8px;
}
.body-call-nav a.nav-call {
  background-color: #00cccc;
}
.body-call-nav a.nav-call:hover,
.body-call-nav a.nav-call:focus {
  background-color: #009999;
}
.body-call-nav a.nav-video {
  background-color: #f10075;
}
.body-call-nav a.nav-video:hover,
.body-call-nav a.nav-video:focus {
  background-color: #be005c;
}
.body-call-nav a.nav-msg {
  background-color: #0168fa;
}
.body-call-nav a.nav-msg:hover,
.body-call-nav a.nav-msg:focus {
  background-color: #0153c7;
}

.body-actions {
  display: flex;
  position: relative;
  margin-top: -2px;
}
.body-actions a {
  display: flex;
  align-items: flex-end;
  color: #1b2e4b;
}
.body-actions a:hover,
.body-actions a:focus {
  color: #0168fa;
}
.body-actions a svg {
  width: 16px;
  stroke-width: 2.6px;
  margin-right: 5px;
}
.body-actions a + a {
  margin-left: 20px;
}

.body-content-nav .nav-link {
  color: #1b2e4b;
  display: flex;
  align-items: center;
  padding: 0;
}
.body-content-nav .nav-link:hover,
.body-content-nav .nav-link:focus {
  color: #0168fa;
}
.body-content-nav .nav-link + .nav-link {
  margin-top: 10px;
}

.body-content-nav svg {
  position: relative;
  margin-top: -2px;
  width: 18px;
  margin-right: 12px;
}

.note {
width:500px
}
.note-button{
margin-top:10px
}
.toast-header{
background-color: inherit
}
.toast{
background-color:inherit
}
.btn-white{
background-color:inherit
}