#invite-user-form-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    z-index: 10000;
}

#invite-user-form-wrapper .tint {
    display: flex;
    flex: 1;
    background-color: rgba(0, 0, 0, .7);
}

#invite-user-form-wrapper .invite-form {
    width: 488px;
    background-color: #FFF;
}

.invite-users-form {
    display: flex;
    justify-content: space-between;
    position: fixed;
    height: 100vh;
    background: #FFFFFF;
    z-index: 10001;
}

.hidden-invite-users-form {
    visibility: hidden;
    height: 100%;
    background-color: rgb(222, 244, 247);
    opacity: 50%;
    z-index: 10000;
    border-left: 1px solid black;
    padding: 30px;
}

@media screen and (min-width: 1360px) {
    .side-menu {
        width: 26%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1360px) {
    .side-menu {
        width: 30%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
    .side-menu {
        width: 40%;
    }
}

@media screen and (min-width: 640px) and (max-width: 800px) {
    .side-menu {
        width: 50%;
    }
}

@media screen and (min-width: 560px) and (max-width: 640px) {
    .side-menu {
        width: 60%;
    }
}

@media screen and (min-width: 300px) and (max-width: 560px) {
    .side-menu {
        width: 100%;
    }
}

.scrollable-content {
    margin: 0 80px 0 60px;
    flex: 1;
    width: 360px !important;
}

.close-inv-form {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: translate(0%, -50%);
    background: none;
    border-radius: 80px;
}

.inv-users-heading {
    width: 156px;
    height: 70px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
    margin: 80px 0 0 0;
    padding: 0 0 10px 0;
}

.inv-form-email {
    flex-direction: column;
}

.inv-form-email-input {
    width: 325px;
    height: 40px;
    border: 1px solid rgba(25, 48, 62, 0.16);
    box-sizing: border-box;
    border-radius: 2px;
    outline: none;
    resize: none;
    padding: 6px 0px 0px 5px;
}

.inv-many-users-label {
    cursor: pointer;
    margin: 7px 0 0 194px;
    width: 131px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #009933;
}

.inv-many-users-back-label {
    cursor: pointer;
    margin: 0px 0 0 292px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #009933;
}

.inv-form-edit-permissions-label {
    cursor: pointer;
    width: 156px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #009933;
    margin-left: 169px;
}

.assign-properties-label {
    width: 150px;
    height: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: rgba(25, 48, 62, 0.48);
}

.assign-to-properties-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    width: 105px;
    height: 40px;
    background: #19303E;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
}

.assign-to-properties-btn p {
    color: #FFFFFF;
    margin-bottom: 0 !important;
    margin-left: 0.2rem;
}

.inv-form-assign-properties-btn {
    cursor: pointer;
    width: 105px;
    height: 40px;
    background: #19303E;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
}

.inv-form-assign-properties-btn label {
    cursor: pointer;
}

.inv-form-assign-properties-btn img {
    width: 18px;
    height: 18px;
}

.inv-form-assign-properties-btn:hover {
    background: #24465b
}

.inv-form-edit-properties-btn {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
    width: 84px;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.inv-form-edit-properties-btn label {
    cursor: pointer;
}

.inv-form-labels {
    text-transform: uppercase;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: rgba(25, 48, 62, 0.48);
    width: 200px;
    text-transform: uppercase;
}

.inv-form-bottom-container {
    flex: 1;
    margin: 20px 80px 0px 60px;
}

.inv-form-invite-btn {
    cursor: pointer;
    width: 120px;
    height: 40px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.inv-form-invite-btn:hover {
    cursor: pointer;
    background: #00C141;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}

.inv-form-invite-btn label {
    cursor: pointer;
}

.inv-form-invite-btn-disabled {
    pointer-events: none;
    width: 120px;
    height: 40px;
    background: #009933;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    opacity: 0.6;
}

.inv-form-inviting-btn {
    background: #00C141;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 42.5px;
    width: 142px;
    height: 40px;
    box-shadow: 0px 6px 6px rgba(0, 193, 65, 0.16);
    border-radius: 2px;
}

.inv-form-inviting-btn label {
    width: 49px;
    height: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
}

.inv-form-inviting-btn i {
    margin: 1px 0px 0px 15px;
    font-size: 15px;
    color: #FFFFFF;
}

.inv-form-save-btn-failed-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 169px;
    min-height: 40px;
    background: #00C141;
    box-shadow: 0px 2px 2px rgba(0, 193, 65, 0.08);
    border-radius: 2px;
}

.inv-form-save-btn-failed-submit label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 0 !important;
}

.inv-form-close-btn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-decoration-line: underline;
    color: rgba(25, 48, 62, 0.8);
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inv-form-close-btn label {
    cursor: pointer;
}

.inv-many-email-input {
    width: 325px;
    height: 120px;
    resize: none;
    border: 1px solid rgba(25, 48, 62, 0.16);
    outline: none;
    padding: 0;
}

.inv-form-back-btn {
    cursor: pointer;
    background: rgba(25, 48, 62, 0.08);
    border-radius: 20px;
    min-width: 40px;
    min-height: 40px;
    margin: 37px 0px 0px 40px;
}

.inv-form-back-btn-hidden {
    cursor: pointer;
    background: rgba(25, 48, 62, 0.08);
    border-radius: 20px;
    min-width: 40px;
    min-height: 40px;
    margin: 37px 0px 0px 40px;
    visibility: hidden;
}

.inv-form-close-x {
    margin: 42px 0px 0px 350px;
}

.inv-form-roles-dropdown {
    padding: 0.5rem;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24) !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    height: 40px;
    -webkit-appearance: auto !important;
    appearance: 1 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
}

.inv-form-roles-dropdown-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    min-width: 250px;
    height: 43px;
    width: 325px;
}

// .inv-form-roles-dropdown-wrapper:hover {
//     border: 1px solid #19303E;
//     box-sizing: border-box;
//     box-shadow: 0px 4px 4px rgba(25, 48, 62, 0.08);
//     border-radius: 2px;
// }

// .inv-form-roles-dropdown-wrapper:focus-within {
//     border: 0.5px solid #19303E;
//     box-sizing: border-box;
//     box-shadow: 0px 4px 4px rgba(25, 48, 62, 0.08);
//     border-radius: 2px;
// }

.line {
    height: 1px;
    background: black;
    width: 250px;
}

#modify-role-view {
    margin: 0 80px 0 60px;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto !important;
}

.modify-role-heading {
    width: 156px;
    height: 110px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
    margin: 80px 0 0 0;
    padding: 0 0 10px 0;
}

.modify-role-dropdown {
    border: hidden !important;
    padding: 0.5rem;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px !important;
    height: 40px;
    width: 100%;
    -webkit-appearance: auto !important;
    appearance: 1 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    padding: 0 !important;
}


.modify-role-dropdown-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    min-width: 250px;
    height: 43px;
}

.inv-form-permissions-component {
    width: 280px;
    justify-content: space-between;
}

.inv-form-permissions-labels {
    min-width: 157px;
}

.modify-permissions-btn {
    cursor: pointer;
    width: 170px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #19303E;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
}

.modify-permissions-btn label {
    cursor: pointer;
}

.modify-permissions-btn:hover {
    background: #24465b;
}

#assign-properties-view {
    margin: 0 80px 0 60px;
    height: 85%;
    width: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.assign-properties-heading {
    width: 156px;
    height: 110px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
    margin: 80px 0 0 0;
    padding: 0 0 10px 0;
}

.assign-property-boxes {
    width: 325px;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #19303E;
}

.property-box-checked {
    background: rgba(0, 193, 65, 0.08);
    border-radius: 2px;
}

.assign-properties-deselect {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #009933;
}

.assign-properties-deselect-hidden {
    visibility: hidden;
    font-size: 12px;
    height: 15px;
}

.deselect-properties-checkbox {
    cursor: pointer;
    height: 15px;
    width: 18px;
}

.assign-properties-btn {
    cursor: pointer;
    background: #19303E;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
    width: 200px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.assign-properties-btn label {
    cursor: pointer;
}

.assign-properties-btn-disabled {
    pointer-events: none;
    background: #19303E;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
    width: 200px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
}

.tag-input {
    height: 65px;
}

#successful-inv-view {
    margin: 0 80px 0 60px;
    height: 85%;
    width: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.successful-inv-heading {
    width: 156px;
    height: 70px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #19303E;
    margin: 80px 0 0 0;
    padding: 0 0 10px 0;
}

.invited-user-avatar {
    background: #A3B8C6;
    border-radius: 20px;
    min-width: 28px;
    height: 28px;
}

.inv-form-role-info-icon {
    position: relative;
}

.inv-form-role-info-icon:hover~.inv-form-roles-info-container {
    visibility: visible;
}

.inv-form-roles-info-container {
    z-index: 1;
    position: absolute;
    visibility: hidden;
    background: #F6F7F7;
    box-shadow: 0px 0px 2px #19303e, 0px 4px 8px #19303e;
    border-radius: 2px;
    width: 180px;
    top: 33px;
    left: 163px;
}

.inv-form-roles-info-container label {
    margin: 6px 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #19303E;
    text-align: center;
}

.invited-users-ok-btn {
    cursor: pointer;
    width: 120px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 48, 62, 0.24);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(25, 48, 62, 0.08);
    border-radius: 2px;
}

.invited-users-ok-btn label {
    cursor: pointer;
}


// .modify-role-dropdown-wrapper:hover {
//     border: 1px solid #19303E;
//     box-sizing: border-box;
//     box-shadow: 0px 4px 4px rgba(25, 48, 62, 0.08);
//     border-radius: 2px;
// }

// .modify-role-dropdown-wrapper:focus-within {
//     border: 0.5px solid #19303E;
//     box-sizing: border-box;
//     box-shadow: 0px 4px 4px rgba(25, 48, 62, 0.08);
//     border-radius: 2px;
// }