.user-settings-side-menu {
  display: flex;
  width: 340px;
  min-height: 100vh;
  background-color: rgba(25, 48, 62, .08);
  padding: 57px 0;
  justify-content: center;
}

.side-menu-interface {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 180px;
}

.side-menu-first-submenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  flex-grow: 0;
  margin: 0px 0px;
  height: 155px;
}

.side-menu-first-heading {
  position: static;
  height: 24px;
  left: 0px;
  top: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(25, 48, 62, 0.48);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 16px;
}

.submenu-options {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;

}

.submenu-options p {
  margin-bottom: 0 !important;
  padding: 4px 0px;
}

.submenu-options p:hover {
  cursor: pointer;
  color: #009933;
}

.submenu-option-link {
  margin-bottom: 0 !important;
  padding: 4px 0px;
}

.submenu-option-link:hover {
  cursor: pointer;
  color: #009933;
}

.side-menu-second-submenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
  padding: 30px 0px 0px 0px;
}

.side-menu-second-heading {
  position: static;
  width: 103px;
  height: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(25, 48, 62, 0.48);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 16px;
}