.list-filters-section .filters-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

.list-filters-section .filters-wrapper .list-filter {
    margin-bottom: 10px;
}

.list-filters-section .filters-wrapper .list-filter-label-wrapper {
    display: flex;
    line-height: 30px;
}
.list-filters-section .filters-wrapper .list-filter-label-wrapper label {
    font-weight: 600;
    font-size: 15px;
    color: #4A4A4A;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
    line-height: 25px;
}

.list-filters-section .filters-wrapper .options-filter-container {
    display: flex;
    flex-direction: column;
}

.list-filters-section .filters-wrapper .options-filter-container > * {
    display: flex;
    flex: 1;
    margin: 5px 0;
}

.list-filters-section input,
.list-filters-section select {
    border: 1px solid #ddd;
    box-shadow: none;
    outline: none;
    line-height: 20px;
    height: 30px;
    padding: 5px 10px;
}


